import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
// core components
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import moments from "moment-timezone";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { getSimpananRekening, getRekeningDropdown } from "stores";
import "./Pagination.css";
import CurrencyInput from "react-currency-input-field";
import NotificationAlert from "react-notification-alert";

function SimpananRekening() {
  const dispatch = useDispatch();
  const simpanan = useSelector((state) => state.pengajuanReducer);

  const [listSimpanan, setListSimpanan] = React.useState([]);
  const [tglAwal, setTglAwal] = React.useState(
    new Date(new Date().getFullYear(), 0, 1).setHours(0, 0, 0, 0)
  );
  const [tglAkhir, setTglAkhir] = React.useState(new Date());

  const notificationAlertRef = React.useRef(null);

  const [modal, setModal] = React.useState(false);
  const [modalImage, setModalImage] = React.useState(false);
  const [detailSimpanan, setDetailSimpanan] = React.useState(null);
  const [pending, setPending] = React.useState(false);

  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");

  // const [modalAddPengajuan, setModalAddPengajuan] = React.useState(false);
  // const [modalEdit, setModalEdit] = React.useState(false);
  // const [idKebutuhan, setIdKebutuhan] = React.useState("");
  // const [validasiKebutuhan, setValidasiKebutuhan] = React.useState(false);
  // const [listKebutuhan, setListKebutuhan] = React.useState([]);
  // const [idSo, setIdSo] = React.useState("");
  // const [statusCekIdSo, setStatusCekIdSo] = React.useState(0); // 0 tidak terpakai,1 terpakai false, 2 terpakai true
  // const [idPo, setIdPo] = React.useState("");
  // const [statusCekIdPo, setStatusCekIdPo] = React.useState(0); // 0 tidak terpakai,1 terpakai false, 2 terpakai true
  // const [tipePengajuan, setTipePengajuan] = React.useState(""); //topup atau transaksi
  // const [idRekeningAsal, setIdRekeningAsal] = React.useState("");
  // const [validasiRekeningAsal, setValidasiRekeningAsal] = React.useState(false);
  const [idRekening, setIdRekening] = React.useState("");
  // const [validasiRekeningTujuan, setValidasiRekeningTujuan] =
  //   React.useState(false);
  const [listRekening, setListRekening] = React.useState([]);
  // const [nominalPengajuan, setNominalPengajuan] = React.useState(0);
  // const [validasiNominalPengajuan, setValidasiNominalPengajuan] =
  //   React.useState(false);
  // const [deskripsiPengajuan, setDeskripsiPengajuan] = React.useState("");
  // const [validasiDeskripsi, setValidasiDeskkripsi] = React.useState(false);

  // const [urlImage, setUrlImage] = React.useState("");
  // const [dokumen, setDokumen] = React.useState([]);

  // const [dokumenValidasi, setDokumenValidasi] = React.useState(false);

  // const [statusPengajuan, setStatusPengajuan] = React.useState("");
  // const [validasiStatusPengajuan, setValidasiStatusPengajuan] =
  //   React.useState(false);

  // const [note, setNote] = React.useState("");
  // const [validasiNote, setValidasiNote] = React.useState(false);

  // function handleFileChange(e) {
  //   // const selectedFiles = Array.from(e.target.files);
  //   setDokumen([...dokumen, ...Array.from(e.target.files)]);
  //   setDokumenValidasi(false);
  // }

  // const removeDokumen = (index) => {
  //   setDokumen(dokumen.filter((_, i) => i !== index));
  // };

  // const handleSearch = (e) => {
  //   setSearchText(e.target.value);
  // };
  // const handleClearSearch = () => {
  //   setSearchText("");
  // };
  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];
  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };

  const customStyles = {
    rows: {
      style: {
        marginTop: 5,
        marginBottom: 5,
        // height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const columns = [
    {
      name: "No",
      width: "80px",
      center: true,
      selector: (row, index) => index + simpanan.listSimpanan.pagingCounter,
    },
    {
      name: "Rekening Masuk",
      width: "200px",
      center: true,
      selector: (row) =>
        row.rekeningTujuan.length === 0
          ? "-"
          : row.rekeningTujuan[0].namaRekening,
    },
    {
      name: "Rekening Keluar",
      width: "200px",
      center: true,
      selector: (row) =>
        row.rekeningAsal.length === 0 ? "-" : row.rekeningAsal[0].namaRekening,
    },

    {
      name: "Jenis Kebutuhan",
      width: "200px",
      center: true,
      selector: (row) =>
        row.kebutuhan.length === 0
          ? row.pengajuan[0].idKebutuhan
          : row.kebutuhan[0].namaKebutuhan,
    },
    {
      name: "Debit",
      width: "200px",
      // center: true,
      right: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.debit);
        return formattedValue;
      },
    },
    {
      name: "Kredit",
      width: "200px",
      // center: true,
      right: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.kredit);
        return formattedValue;
      },
    },
    {
      name: "Mutasi",
      width: "200px",
      // center: true,
      right: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.saldoAkhir);
        return formattedValue;
      },
    },
    {
      name: "Tanggal Input",
      width: "200px",
      center: true,
      selector: (row) =>
        moments
          .tz(new Date(row.tglDebitKredit), "Asia/Jakarta")
          .format("DD-MM-YYYY"),
    },
    {
      name: "Aksi",
      width: "200px",
      center: true,
      selector: (row) => {
        return (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                onClick={() => {
                  // setDetailPengajuan(row);
                  // setModal(true);
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Detail</Tooltip>}
                >
                  <i
                    style={{ color: "#447DF7", fontSize: 16 }}
                    className="fas fa-info-circle"
                  ></i>
                </OverlayTrigger>
              </div>
              {/* <div
                onClick={() => {
                  if (row.statusPengajuan === "Menunggu") {
                    setDetailPengajuan(row);
                    setModalEdit(true);
                  } else {
                    null;
                  }
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor:
                    row.statusPengajuan === "Menunggu" ? "pointer" : "unset",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Edit </Tooltip>}
                >
                  <i
                    style={{
                      color:
                        row.statusPengajuan === "Menunggu" ? "#478964" : "gray",
                      fontSize: 16,
                    }}
                    className="fas fa-edit"
                  ></i>
                </OverlayTrigger>
              </div> */}

              {/* <div
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor:
                    row.statusPengajuan === "Menunggu" ? "pointer" : "unset",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Hapus</Tooltip>}
                >
                  <i
                    style={{
                      color:
                        row.statusPengajuan === "Menunggu" ? "#FF0000" : "gray",
                      fontSize: 16,
                    }}
                    className="fas fa-trash"
                  ></i>
                </OverlayTrigger>
              </div> */}
              {/* <div
                onClick={() => {
                  if (row.statusSo === "Cancel") {
                    null;
                  } else {
                    row.suratJalan.length === 0 ? null : cetakSjPdf(row);
                  }
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor:
                    row.suratJalan.length === 0 || row.statusSo === "Cancel"
                      ? "unset"
                      : "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Cetak PDF</Tooltip>}
                >
                  <i
                    style={{
                      color:
                        row.suratJalan.length === 0 || row.statusSo === "Cancel"
                          ? "grey"
                          : "#FF0000",
                      fontSize: 16,
                    }}
                    className="fas fa-file-pdf"
                  ></i>
                </OverlayTrigger>
              </div> */}
            </div>
          </>
        );
      },
    },
    // {
    //   name: "Rekening Asal",
    //   width: "200px",
    //   center: true,
    //   selector: (row) => row.rekeningAsal.namaRekening,
    // },
    // {
    //   name: "Rekening Tujuan",
    //   width: "200px",
    //   center: true,
    //   selector: (row) => row.rekeningTujuan.namaRekening,
    // },
    // {
    //   name: "Tanggal Pengajuan",
    //   width: "200px",
    //   center: true,
    //   selector: (row) =>
    //     moments
    //       .tz(new Date(row.tglPengajuan), "Asia/Jakarta")
    //       .format("DD-MM-YYYY"),
    // },
    // {
    //   name: "Pembuat",
    //   width: "200px",
    //   center: true,
    //   selector: (row) => row.userPengajuan.namaLengkap,
    // },
    // {
    //   name: "Nominal Pengajuan",
    //   width: "200px",
    //   center: true,
    //   selector: (row) => {
    //     const formattedValue = new Intl.NumberFormat("id-ID", {
    //       style: "currency",
    //       currency: "IDR",
    //       minimumFractionDigits: 0,
    //     }).format(row.nominalPengajuan);
    //     return formattedValue;
    //   },
    // },
    // {
    //   name: "Status Mesin Selesai",
    //   width: "200px",
    //   center: true,
    //   selector: (row) => row.totalOrders.Selesai,
    // },
  ];

  const history = useHistory();
  React.useEffect(() => {
    let tmp =
      simpanan.listSimpanan.data &&
      simpanan.listSimpanan.data.map((val, index) => {
        return {
          ...val,
          // totalOrders: {
          //   Progress: val.orders.filter(
          //     (order) => order.statusProduk === "Progress"
          //   ).length,
          //   Selesai: val.orders.filter(
          //     (order) => order.statusProduk === "Selesai"
          //   ).length,
          // },
        };
      });

    setListSimpanan(tmp);

    if (simpanan.listSimpanan.length != 0) {
      setPending(false);
    }
  }, [simpanan.listSimpanan]);

  React.useEffect(() => {
    getSimpananRekening(dispatch, {
      idRekening: idRekening && idRekening.value,
      page: page + 1,
      limit: totalPerHalaman,
      tglAwal: moments
        .tz(new Date(tglAwal), "Asia/Jakarta")
        .format("YYYY-MM-DD"),
      tglAkhir: moments
        .tz(new Date(tglAkhir), "Asia/Jakarta")
        .format("YYYY-MM-DD"),
    });
  }, [page, totalPerHalaman, tglAwal, tglAkhir, listRekening, idRekening]);

  React.useEffect(() => {
    getRekeningDropdown(dispatch);
  }, []);

  React.useEffect(() => {
    let tmpRekening =
      simpanan.rekeningDropdown.data &&
      simpanan.rekeningDropdown.data.map((val, index) => {
        return {
          value: val.idRekening,
          label: val.namaRekening,
        };
      });

    setListRekening(tmpRekening);
    setIdRekening(tmpRekening && tmpRekening[0]);
  }, [simpanan.rekeningDropdown]);

  const notify = (val) => {
    var type = val.code === 0 ? "info" : "danger";

    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <div>
            <b style={{ color: "#000000" }}>{val.message}</b>
          </div>
        </div>
      ),
      type: type,
      icon: val.code === 0 ? "fas fa-box-open" : "fas fa-times-circle",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <Row
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <Col>
            <div style={{ fontSize: 25, fontWeight: "bold" }}>
              Simpanan Rekening
            </div>
          </Col>
          <Col style={{ textAlign: "right" }}>
            {/* <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div>
                <InputGroup>
                  <Form.Control
                    style={{
                      paddingLeft: "10px",
                      width: "300px",
                    }}
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="ID Pengajuan"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      style={{ cursor: "pointer" }}
                      onClick={handleClearSearch}
                    >
                      <i class="fas fa-times"></i>
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </div> */}
          </Col>
        </Row>
        <Col md="12">
          <Row>
            <Col md="2">
              <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                Tanggal Awal
              </div>
              <Form.Group
                style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
              >
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Date Picker Here",
                    readOnly: true,
                    style: {
                      cursor: "default",
                      color: "black",
                      backgroundColor: "white",
                    },
                  }}
                  onChange={(e) => {
                    e.toDate().setHours(0, 0, 0, 0),
                      setTglAwal(e.toDate().setHours(0, 0, 0, 0));
                  }}
                  value={tglAwal}
                  initialValue={tglAwal}
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                ></ReactDatetime>
              </Form.Group>
            </Col>
            <Col md="2">
              <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                Tanggal Akhir
              </div>
              <Form.Group
                style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
              >
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Date Picker Here",
                    readOnly: true,
                    style: {
                      cursor: "default",
                      color: "black",
                      backgroundColor: "white",
                    },
                  }}
                  onChange={(e) => {
                    e.toDate().setHours(23, 59, 0, 0),
                      setTglAkhir(e.toDate().setHours(23, 59, 0, 0));
                  }}
                  value={tglAkhir}
                  initialValue={tglAkhir}
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                ></ReactDatetime>
              </Form.Group>
            </Col>
            <Col md="5">
              <div
                style={{
                  marginBottom: 20,
                  alignItems: "center",
                }}
              >
                <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                  Rekening
                </div>
                <div style={{ width: 250 }}>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={idRekening}
                    onChange={(value) => {
                      setIdRekening(value);
                      setPage(0);
                      // setStatusPesan(value);
                    }}
                    options={listRekening}
                    placeholder="Pilih Rekening"
                  />
                </div>
              </div>
              {/* <Button
                style={{
                  marginLeft: 20,
                  marginBottom: 0,
                  backgroundColor: "rgba(68,125,247,1)",
                  borderColor: "rgba(68,125,247,1)",
                }}
                onClick={() => {
                  // exportAllSj();
                  setModalAddPengajuan(true);
                }}
              >
                <i
                  className="fas fa-file-invoice-dollar"
                  style={{ marginRight: 10 }}
                ></i>
                Buat Pengajuan
              </Button> */}
            </Col>
            <Col md="3">
              <div
                style={{
                  marginBottom: 20,
                  alignItems: "center",
                }}
              >
                <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                  Total Saldo Akhir
                </div>
                <div style={{ width: 250 }}>
                  <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                    {new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      minimumFractionDigits: 0,
                    }).format(simpanan.listSimpanan.saldoAkhirTotal)}
                  </div>
                </div>
              </div>
              {/* <Button
                style={{
                  marginLeft: 20,
                  marginBottom: 0,
                  backgroundColor: "rgba(68,125,247,1)",
                  borderColor: "rgba(68,125,247,1)",
                }}
                onClick={() => {
                  // exportAllSj();
                  setModalAddPengajuan(true);
                }}
              >
                <i
                  className="fas fa-file-invoice-dollar"
                  style={{ marginRight: 10 }}
                ></i>
                Buat Pengajuan
              </Button> */}
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <div
                style={{
                  marginBottom: 20,
                  alignItems: "center",
                }}
              >
                <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                  Saldo Awal Filter
                </div>
                <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                  {new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                  }).format(simpanan.listSimpanan.saldoAwal)}
                </div>
              </div>
            </Col>
            <Col md="4">
              <div
                style={{
                  marginBottom: 20,
                  alignItems: "center",
                }}
              >
                <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                  Saldo Akhir Filter
                </div>
                <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                  {new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                  }).format(simpanan.listSimpanan.saldoAkhir)}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        {/* <Row>
          <Col>
            <Button
              onClick={() => {
                setModalImport(true);
              }}
              className="btn-social"
              variant="twitter"
              style={{
                backgroundColor: "rgba(40,118,74,1)",
                borderColor: "rgba(40,118,74,1)",
              }}
            >
              <i className="fas fa-file-excel" style={{ marginRight: 10 }}></i>
              Import Excel
            </Button>
            <Button
              onClick={() => {
                window.open(
                  "https://docs.google.com/spreadsheets/d/1Qxfk5Xe0Xbjr39htdHLRoiA6_wSCEWaN/edit?usp=sharing&ouid=116475921970373937456&rtpof=true&sd=true",
                  "_blank"
                );
              }}
              className="btn-social"
              variant="twitter"
              style={{
                backgroundColor: "rgba(64,123,255,1)",
                borderColor: "rgba(64,123,255,1)",
                marginLeft: 10,
              }}
            >
              <i
                className="fas fa-cloud-download-alt"
                style={{ marginRight: 10 }}
              ></i>
              Download Format Import
            </Button>
          </Col>
          <Col>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div>
                <InputGroup>
                  <Form.Control
                    style={{
                      paddingLeft: "10px",
                      width: "300px",
                    }}
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Nama Mesin/Kode Mesin"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      style={{ cursor: "pointer" }}
                      onClick={handleClearSearch}
                    >
                      <i class="fas fa-times"></i>
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </div>
          </Col>
        </Row> */}

        <Row>
          <Col md="12">
            <Card>
              <Card.Body>
                <DataTable
                  columns={columns}
                  data={listSimpanan}
                  //   pagination
                  customStyles={customStyles}
                  paginationPerPage={totalPerHalaman}
                  //   onChangePage={handlePageChange}
                  progressPending={pending}
                />

                <div style={{ marginTop: 35 }}>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col>
                      <Row style={{ alignItems: "center" }}>
                        <div
                          style={{
                            paddingRight: 0,
                            marginLeft: 20,
                            marginRight: 10,
                          }}
                        >
                          <label
                            style={{
                              fontWeight: "bold",
                              textTransform: "none",
                              fontSize: 14,
                            }}
                          >
                            Rows per page
                          </label>
                        </div>
                        <Col sm="3" style={{ paddingLeft: 0 }}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="Pilih Client"
                            value={options.find(
                              (option) => option.value === totalPerHalaman
                            )}
                            onChange={handlePageSizeChange}
                            options={options}
                            placeholder="Rows per page"
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col>
                      <ReactPaginate
                        forcePage={page}
                        containerClassName="paginations justify-content-end"
                        previousClassName="pages-items-containers"
                        previousLinkClassName="pages-links-labels"
                        nextClassName="pages-items-containers"
                        nextLinkClassName="pages-links-labels"
                        breakClassName="pages-items-containers"
                        pageCount={simpanan.listSimpanan.totalPages}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        pageLinkClassName="pages-links-labels"
                        pageClassName="pages-items-containers"
                        activeClassName="actives"
                        activeLinkClassName="activess"
                        nextLabel=" > "
                        previousLabel=" < "
                        initialPage={page}
                        disableInitialCallback={true}
                        onPageChange={(val) => {
                          setPage(val.selected);
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Modal Import Produk */}
      {/* <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
          setDetailPengajuan(null);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Detail Pengajuan
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  ID Pengajuan
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPengajuan && detailPengajuan.idPengajuan}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Kebutuhan
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPengajuan && detailPengajuan.kebutuhan.namaKebutuhan}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Nominal Pengajuan
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPengajuan &&
                    new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      minimumFractionDigits: 0,
                    }).format(detailPengajuan.nominalPengajuan)}
                </Col>
              </Row>

              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Rekening Asal
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPengajuan && detailPengajuan.rekeningAsal.namaRekening}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Rekening Tujuan
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPengajuan &&
                    detailPengajuan.rekeningTujuan.namaRekening}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Pembuat
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPengajuan && detailPengajuan.userPengajuan.namaLengkap}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Tanggal Pengajuan
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPengajuan &&
                    moments
                      .tz(
                        new Date(detailPengajuan.tglPengajuan),
                        "Asia/Jakarta"
                      )
                      .format("DD-MM-YYYY")}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Deskripsi Pengajuan
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPengajuan && detailPengajuan.deskripsiPengajuan}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Approval
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPengajuan && detailPengajuan.userApprove.length === 0
                    ? "-"
                    : detailPengajuan &&
                      detailPengajuan.userApprove[0].namaLengkap}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Tanggal Approve
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPengajuan && !detailPengajuan.tglApprove
                    ? "-"
                    : detailPengajuan &&
                      moments
                        .tz(
                          new Date(detailPengajuan.tglApprove),
                          "Asia/Jakarta"
                        )
                        .format("DD-MM-YYYY")}
                </Col>
              </Row>

              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Status Pengajuan
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPengajuan &&
                  detailPengajuan.statusPengajuan === "Menunggu" ? (
                    <b style={{ color: "rgb(255,163,47)" }}>Menunggu</b>
                  ) : detailPengajuan &&
                    detailPengajuan.statusPengajuan === "Ditolak" ? (
                    <b style={{ color: "rgb(251,59,70)" }}>Ditolak</b>
                  ) : detailPengajuan &&
                    detailPengajuan.statusPengajuan === "Revisi" ? (
                    <b style={{ color: "rgb(247, 0, 239)" }}>Revisi</b>
                  ) : (
                    <b style={{ color: "rgb(36,204,239)" }}>Disetujui</b>
                  )}
                </Col>
              </Row>

              <div style={{ marginTop: 20 }}>
                <b>Bukti Dokumen Pengajuan</b>
              </div>
              <hr></hr>
              <Row>
                {detailPengajuan &&
                  detailPengajuan.buktiFotoPengajuan.map((val, index) => {
                    return (
                      <>
                        <Col sm="4">
                          <Image
                            onClick={() => {
                              setUrlImage(val);
                              setModalImage(true);
                            }}
                            src={val}
                            style={{
                              objectFit: "contain",
                              width: "200px",
                              height: "300px",
                              cursor: "pointer",
                            }}
                            thumbnail
                          />
                        </Col>
                      </>
                    );
                  })}
              </Row>

              <div style={{ marginTop: 20 }}>
                <b>Bukti Dokumen Approval</b>
              </div>
              <hr></hr>
              <Row>
                {detailPengajuan &&
                detailPengajuan.buktiFotoApprove.length === 0 ? (
                  <span style={{ color: "grey", marginLeft: 20 }}>
                    Belum memiliki dokumen approval.
                  </span>
                ) : (
                  detailPengajuan &&
                  detailPengajuan.buktiFotoApprove.map((val, index) => {
                    return (
                      <>
                        <Col sm="4">
                          <Image
                            onClick={() => {
                              setUrlImage(val);
                              setModalImage(true);
                            }}
                            src={val}
                            style={{
                              objectFit: "contain",

                              width: "200px",
                              height: "300px",
                              cursor: "pointer",
                            }}
                            thumbnail
                          />
                        </Col>
                      </>
                    );
                  })
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Modal> */}

      {/* Modal Add Pengajuan Biaya */}
      {/* <Modal
        size="md"
        show={modalAddPengajuan}
        onHide={() => {
          setModalAddPengajuan(false);
          setPending(false);
          setIdKebutuhan("");
          setValidasiKebutuhan(false);
          setIdRekeningAsal("");
          setValidasiRekeningAsal(false);
          setIdRekeningTujuan("");
          setValidasiRekeningTujuan(false);
          setNominalPengajuan(0);
          setValidasiNominalPengajuan(false);
          setDeskripsiPengajuan("");
          setValidasiDeskkripsi(false);
          setIdSo("");
          setIdPo("");
          setStatusCekIdSo(0);
          setStatusCekIdPo(0);
          setDokumenValidasi(false);
          setDokumen([]);
          setUrlImage("");
          setDetailPengajuan(null);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Form Buat Pengajuan Biaya
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Pilih Kebutuhan <span className="star">*</span>
                  </label>

                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={idKebutuhan}
                    onChange={(value) => {
                      setIdKebutuhan(value);
                      setValidasiKebutuhan(false);
                    }}
                    options={listKebutuhan}
                    placeholder="Pilih Kebutuhan"
                  />
                  {validasiKebutuhan === false ? null : (
                    <label
                      className="error"
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Kebutuhan tidak boleh kosong.
                    </label>
                  )}
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Pilih Rekening Asal <span className="star">*</span>
                  </label>

                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={idRekeningAsal}
                    onChange={(value) => {
                      setIdRekeningAsal(value);
                      setValidasiRekeningAsal(false);
                    }}
                    options={listRekening}
                    placeholder="Pilih Rekening Asal"
                  />
                  {validasiRekeningAsal === false ? null : (
                    <label
                      className="error"
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Rekening asal tidak boleh kosong.
                    </label>
                  )}
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Pilih Rekening Tujuan <span className="star">*</span>
                  </label>

                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={idRekeningTujuan}
                    onChange={(value) => {
                      setIdRekeningTujuan(value);
                      setValidasiRekeningTujuan(false);
                    }}
                    options={listRekening}
                    placeholder="Pilih Rekening Tujuan"
                  />
                  {validasiRekeningTujuan === false ? null : (
                    <label
                      className="error"
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Rekening tujuan tidak boleh kosong.
                    </label>
                  )}
                </Form.Group>
                <Form.Group style={{ marginTop: 15 }}>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Nominal Pengajuan <span className="star">*</span>
                  </label>
                  <CurrencyInput
                    className="custom-input"
                    style={{
                      padding: 5,
                      borderRadius: 4,
                      width: "100%",
                      height: "40px",
                      borderWidth: "1px",
                      borderTopColor: "rgba(227, 227, 227, 0.3)",
                      borderLeftColor: "rgba(227, 227, 227, 0.3)",
                      borderBottomColor: "rgba(227, 227, 227, 1)",
                      borderRightColor: "rgba(227, 227, 227, 1)",
                    }}
                    name="nominal"
                    placeholder="Nominal Pengajuan"
                    prefix="Rp "
                    onValueChange={(value) => {
                      setNominalPengajuan(value);
                      setValidasiNominalPengajuan(false);
                    }}
                    defaultValue={nominalPengajuan}
                  />
                  {validasiNominalPengajuan === false ? null : (
                    <label
                      className="error"
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Nominal pengajuan tidak boleh kosong.
                    </label>
                  )}
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Deskripsi <span className="star">*</span>
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setDeskripsiPengajuan(e.target.value);
                      setValidasiDeskkripsi(false);
                    }}
                    placeholder="Deskripsi Pengajuan"
                    value={deskripsiPengajuan}
                  ></Form.Control>
                  {validasiDeskripsi === false ? null : (
                    <label
                      className="error"
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Deskripsi tidak boleh kosong.
                    </label>
                  )}
                </Form.Group>
                <Row
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Col sm={9}>
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        ID Sales Order
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setIdSo(e.target.value);
                        }}
                        placeholder="ID Sales Order"
                        value={idSo}
                      ></Form.Control>
                      {statusCekIdSo === 0 ? null : statusCekIdSo === 1 ? (
                        <label
                          style={{
                            textTransform: "none",
                            color: "red",
                            fontWeight: "bold",
                          }}
                        >
                          Not-Valid
                        </label>
                      ) : (
                        <label
                          style={{
                            textTransform: "none",
                            color: "green",
                            fontWeight: "bold",
                          }}
                        >
                          Valid
                        </label>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Button
                      disabled={idSo === "" ? true : false}
                      className="btn-fill"
                      type="submit"
                      // variant="info"
                      style={{
                        backgroundColor: "#00BFFF",
                        border: 0,
                        marginTop: 18,
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        cekKodeSo();
                      }}
                    >
                      Cek
                    </Button>
                  </Col>
                </Row>

                <Row
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Col sm={9}>
                    <Form.Group>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        ID PO
                      </label>
                      <Form.Control
                        onChange={(e) => {
                          setIdPo(e.target.value);
                        }}
                        placeholder="ID Sales Order"
                        value={idPo}
                      ></Form.Control>
                      {statusCekIdPo === 0 ? null : statusCekIdPo === 1 ? (
                        <label
                          style={{
                            textTransform: "none",
                            color: "red",
                            fontWeight: "bold",
                          }}
                        >
                          Not-Valid
                        </label>
                      ) : (
                        <label
                          style={{
                            textTransform: "none",
                            color: "green",
                            fontWeight: "bold",
                          }}
                        >
                          Valid
                        </label>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Button
                      disabled={idPo === "" ? true : false}
                      className="btn-fill"
                      type="submit"
                      // variant="info"
                      style={{
                        backgroundColor: "#00BFFF",
                        border: 0,
                        marginTop: 18,
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        cekKodePo();
                      }}
                    >
                      Cek
                    </Button>
                  </Col>
                </Row>

                <div>
                  <div>
                    <label style={{ color: "black", fontWeight: "bold" }}>
                      Upload Dokumen{" "}
                      <span className="star" style={{ textTransform: "none" }}>
                        *{" "}
                        <span style={{ color: "blue" }}>
                          (Maksimal 2 Dokumen)
                        </span>
                      </span>
                    </label>
                  </div>

                  <label
                    className="label-input-file btn text-white mb-4"
                    htmlFor="import2"
                    style={{
                      fontWeight: "bold",
                      backgroundColor:
                        dokumen.length === 2 ? "grey" : "#447DF7",
                      border: 0,
                      marginTop: 10,
                    }}
                  >
                    <div
                      style={{
                        color: "#FFFFFF",
                        textTransform: "none",
                      }}
                    >
                      <i
                        className="fas fa-images"
                        style={{ marginRight: 5 }}
                      ></i>
                      Pilih File
                    </div>

                    <input
                      disabled={pending || dokumen.length === 2 ? true : false}
                      type="file"
                      id="import2"
                      onClick={(e) => {
                        e.target.value = "";
                      }}
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      accept=".jpg,.jpeg,.png"
                      multiple
                    />
                  </label>
                  {dokumenValidasi === false ? null : (
                    <label
                      className="error"
                      style={{
                        textTransform: "none",
                        color: "red",
                        marginLeft: 10,
                      }}
                    >
                      Dokumen tidak boleh kosong.
                    </label>
                  )}
                  {dokumen.length === 0 ? (
                    <div>
                      <p style={{ color: "GrayText" }}>File kosong</p>
                    </div>
                  ) : (
                    dokumen.map((file, index) => (
                      <div className="d-flex align-items-center" key={index}>
                        <p className="mb-1 mr-1">{file.name}</p>

                        <i
                          className="fa fa-times"
                          style={{
                            cursor: "pointer",
                            color: "red",
                            marginLeft: 10,
                          }}
                          onClick={() =>
                            pending ? null : removeDokumen(index)
                          }
                        />
                      </div>
                    ))
                  )}
                </div>
              </Form>
            </Card.Body>
            <Card.Footer style={{ textAlign: "end" }}>
              <Button
                className="btn-fill"
                type="submit"
                // variant="info"
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={(e) => {
                  e.preventDefault();
                  buatPengajuan();
                }}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal> */}

      {/* Modal Edit Surat Jalan */}
      {/* <Modal
        size="md"
        show={modalEdit}
        onHide={() => {
          setModalEdit(false);
          setPending(false);
          setStatusPengajuan("");
          setValidasiStatusPengajuan(false);
          setNote("");
          setDokumen([]);
          setUrlImage("");
          setValidasiNote(false);
          setDetailPengajuan(null);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Update Pengajuan
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Status Pengajuan <span className="star">*</span>
                  </label>

                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={statusPengajuan}
                    onChange={(value) => {
                      setStatusPengajuan(value);
                      setValidasiStatusPengajuan(false);
                    }}
                    options={[
                      { value: "Ditolak", label: "Ditolak" },
                      { value: "Disetujui", label: "Disetujui" },
                    ]}
                    placeholder="Pilih Status Pengajuan"
                  />
                  {validasiStatusPengajuan === false ? null : (
                    <label
                      className="error"
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Status pengajuan tidak boleh kosong.
                    </label>
                  )}
                </Form.Group>
                {statusPengajuan.value === "Disetujui" ||
                statusPengajuan === "" ? null : (
                  <Form.Group>
                    <label style={{ color: "black", fontWeight: "bold" }}>
                      Keterangan
                    </label>
                    <Form.Control
                      onChange={(e) => {
                        setNote(e.target.value);
                        setValidasiNote(false);
                      }}
                      placeholder="Keterangan"
                      type="text"
                      value={note}
                    ></Form.Control>
                    {validasiNote === true &&
                    statusPengajuan.value === "Ditolak" ? (
                      <label
                        className="error"
                        style={{ textTransform: "none", color: "red" }}
                      >
                        Status pengajuan tidak boleh kosong.
                      </label>
                    ) : null}
                  </Form.Group>
                )}

                {statusPengajuan.value === "Disetujui" ? (
                  <div>
                    <div>
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        Upload Dokumen{" "}
                        <span
                          className="star"
                          style={{ textTransform: "none" }}
                        >
                          
                          <span style={{ color: "blue" }}>
                            (Maksimal 2 Dokumen)
                          </span>
                        </span>
                      </label>
                    </div>

                    <label
                      className="label-input-file btn text-white mb-4"
                      htmlFor="import2"
                      style={{
                        fontWeight: "bold",
                        backgroundColor:
                          dokumen.length === 2 ? "grey" : "#447DF7",
                        border: 0,
                        marginTop: 10,
                      }}
                    >
                      <div
                        style={{
                          color: "#FFFFFF",
                          textTransform: "none",
                        }}
                      >
                        <i
                          className="fas fa-images"
                          style={{ marginRight: 5 }}
                        ></i>
                        Pilih File
                      </div>

                      <input
                        disabled={
                          pending || dokumen.length === 2 ? true : false
                        }
                        type="file"
                        id="import2"
                        onClick={(e) => {
                          e.target.value = "";
                        }}
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                        accept=".jpg,.jpeg,.png"
                        multiple
                      />
                    </label>
                    {dokumenValidasi === false &&
                    statusPengajuan.value === "Disetujui" ? null : (
                      <label
                        className="error"
                        style={{
                          textTransform: "none",
                          color: "red",
                          marginLeft: 10,
                        }}
                      >
                        Dokumen tidak boleh kosong.
                      </label>
                    )}
                    {dokumen.length === 0 ? (
                      <div>
                        <p style={{ color: "GrayText" }}>File kosong</p>
                      </div>
                    ) : (
                      dokumen.map((file, index) => (
                        <div className="d-flex align-items-center" key={index}>
                          <p className="mb-1 mr-1">{file.name}</p>

                          <i
                            className="fa fa-times"
                            style={{
                              cursor: "pointer",
                              color: "red",
                              marginLeft: 10,
                            }}
                            onClick={() =>
                              pending ? null : removeDokumen(index)
                            }
                          />
                        </div>
                      ))
                    )}
                  </div>
                ) : null}
              </Form>
            </Card.Body>
            <Card.Footer style={{ textAlign: "end" }}>
              <Button
                className="btn-fill"
                type="submit"
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={(e) => {
                  e.preventDefault();
                  approvalPengajuan();
                }}
              >
                Update
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal> */}

      {/* Modal show image */}
      {/* <Modal
        size="lg"
        show={modalImage}
        onHide={() => {
          setModalImage(false);
          setUrlImage("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Detail Gambar
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Image
                src={urlImage}
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                }}
                thumbnail
              />
            </Card.Body>
          </Card>
        </Col>
      </Modal> */}
    </>
  );
}

export default SimpananRekening;
