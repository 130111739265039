import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
// core components
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import moments from "moment-timezone";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import {
  getPengajuanAsset,
  tambahPengajuanAsset,
  approvePengajuanAsset,
  getKategoriAsset,
  getLokasiAsset,
  getRekeningDropdown,
  getKategoriAssetDropdown,
  getLokasiAssetDropdown,
} from "stores";
import "./Pagination.css";
import CurrencyInput from "react-currency-input-field";
import NotificationAlert from "react-notification-alert";

function PengajuanAsset() {
  const dispatch = useDispatch();
  const pengajuanBiaya = useSelector((state) => state.pengajuanReducer);
  const asset = useSelector((state) => state.assetReducer);
  const auth = useSelector((state) => state.authReducer);
  const rekening = useSelector((state) => state.rekeningReducer);
  const lokasiAsset = useSelector((state) => state.lokasiAssetReducer);
  const kategoriAsset = useSelector((state) => state.kategoriAssetReducer);
  const [listKategoriAsset, setListKategoriAsset] = React.useState([]);
  const [listLokasiAsset, setListLokasiAsset] = React.useState([]);
  const [listRekening, setListRekening] = React.useState([]);
  const [listPengajuanAsset, setListPengajuanAsset] = React.useState([]);
  const [tglAwal, setTglAwal] = React.useState(
    new Date(new Date().getFullYear(), 0, 1).setHours(0, 0, 0, 0)
  );
  const [tglAkhir, setTglAkhir] = React.useState(new Date());
  const notificationAlertRef = React.useRef(null);

  const [modal, setModal] = React.useState(false);
  const [modalImage, setModalImage] = React.useState(false);
  const [detailPengajuan, setDetailPengajuan] = React.useState(null);
  const [pending, setPending] = React.useState(false);

  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");

  const [modalAddPengajuan, setModalAddPengajuan] = React.useState(false);
  const [modalEdit, setModalEdit] = React.useState(false);
  const [namaBarang, setNamaBarang] = React.useState("");
  const [validasiNamaBarang, setValidasiNamaBarang] = React.useState(false);
  const [idKategori, setIdKategori] = React.useState("");
  const [validasiKategori, setValidasiKategori] = React.useState(false);
  const [idLokasi, setIdLokasi] = React.useState("");
  const [validasiLokasi, setValidasiLokasi] = React.useState(false);

  const [idRekeningAsal, setIdRekeningAsal] = React.useState("");
  const [validasiRekeningAsal, setValidasiRekeningAsal] = React.useState(false);

  const [nominalPengajuan, setNominalPengajuan] = React.useState(0);
  const [validasiNominalPengajuan, setValidasiNominalPengajuan] =
    React.useState(false);
  const [deskripsiPengajuan, setDeskripsiPengajuan] = React.useState("");
  const [validasiDeskripsi, setValidasiDeskripsi] = React.useState(false);

  const [urlImage, setUrlImage] = React.useState("");
  const [dokumen, setDokumen] = React.useState([]);

  const [dokumenValidasi, setDokumenValidasi] = React.useState(false);

  const [statusPengajuan, setStatusPengajuan] = React.useState("");
  const [validasiStatusPengajuan, setValidasiStatusPengajuan] =
    React.useState(false);

  const [note, setNote] = React.useState("");
  const [validasiNote, setValidasiNote] = React.useState(false);

  function handleFileChange(e) {
    // const selectedFiles = Array.from(e.target.files);
    setDokumen([...dokumen, ...Array.from(e.target.files)]);
    setDokumenValidasi(false);
  }

  const removeDokumen = (index) => {
    setDokumen(dokumen.filter((_, i) => i !== index));
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const handleClearSearch = () => {
    setSearchText("");
  };
  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];
  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };

  const customStyles = {
    rows: {
      style: {
        marginTop: 5,
        marginBottom: 5,
        // height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const columns = [
    {
      name: "No",
      width: "80px",
      center: true,
      selector: (row, index) => index + asset.listPengajuanAsset.pagingCounter,
    },
    {
      name: "Aksi",
      width: "200px",
      center: true,
      selector: (row) => {
        return (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                onClick={() => {
                  setDetailPengajuan(row);

                  setModal(true);
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Detail</Tooltip>}
                >
                  <i
                    style={{ color: "#447DF7", fontSize: 16 }}
                    className="fas fa-info-circle"
                  ></i>
                </OverlayTrigger>
              </div>
              {(auth.responLogin && auth.responLogin.role === "Super Admin") ||
              (auth.responLogin && auth.responLogin.role === "Management") ? (
                <div
                  onClick={() => {
                    if (row.statusPengajuan === "Menunggu") {
                      setDetailPengajuan(row);
                      setModalEdit(true);
                    } else {
                      null;
                    }
                  }}
                  style={{
                    width: 25,
                    height: 25,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor:
                      row.statusPengajuan === "Menunggu" ? "pointer" : "unset",
                    margin: 10,
                  }}
                >
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-48903503">Edit </Tooltip>}
                  >
                    <i
                      style={{
                        color:
                          row.statusPengajuan === "Menunggu"
                            ? "#478964"
                            : "gray",
                        fontSize: 16,
                      }}
                      className="fas fa-edit"
                    ></i>
                  </OverlayTrigger>
                </div>
              ) : null}

              {/* <div
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor:
                    row.statusPengajuan === "Menunggu" ? "pointer" : "unset",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Hapus</Tooltip>}
                >
                  <i
                    style={{
                      color:
                        row.statusPengajuan === "Menunggu" ? "#FF0000" : "gray",
                      fontSize: 16,
                    }}
                    className="fas fa-trash"
                  ></i>
                </OverlayTrigger>
              </div> */}
              {/* <div
                onClick={() => {
                  if (row.statusSo === "Cancel") {
                    null;
                  } else {
                    row.suratJalan.length === 0 ? null : cetakSjPdf(row);
                  }
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor:
                    row.suratJalan.length === 0 || row.statusSo === "Cancel"
                      ? "unset"
                      : "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Cetak PDF</Tooltip>}
                >
                  <i
                    style={{
                      color:
                        row.suratJalan.length === 0 || row.statusSo === "Cancel"
                          ? "grey"
                          : "#FF0000",
                      fontSize: 16,
                    }}
                    className="fas fa-file-pdf"
                  ></i>
                </OverlayTrigger>
              </div> */}
            </div>
          </>
        );
      },
    },
    {
      name: "Status Pengajuan",
      width: "200px",
      center: true,
      selector: (row) =>
        row.statusPengajuan === "Menunggu" ? (
          <div
            style={{
              backgroundColor: "rgba(255,163,47,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(255,163,47)",
            }}
          >
            <b style={{ color: "rgb(255,163,47)" }}>Menunggu</b>
          </div>
        ) : row.statusPengajuan === "Ditolak" ? (
          <div
            style={{
              backgroundColor: "rgba(251,59,70,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(251,59,70)",
            }}
          >
            <b style={{ color: "rgb(251,59,70)" }}>Ditolak</b>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "rgba(36,204,239,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(36,204,239)",
            }}
          >
            <b style={{ color: "rgb(36,204,239)" }}>Disetujui</b>
          </div>
        ),
    },
    // {
    //   name: "ID Pengajuan",
    //   width: "200px",
    //   center: true,
    //   selector: (row) => row.idPengajuan,
    // },
    {
      name: "Nama Asset",
      width: "200px",
      center: true,
      selector: (row) => row.namaBarang,
    },
    {
      name: "Kategori Asset",
      width: "200px",
      center: true,
      selector: (row) => row.kategoriAsset[0].namaKategori,
    },
    {
      name: "Lokasi Asset",
      width: "200px",
      center: true,
      selector: (row) => row.lokasiAsset[0].namaLokasi,
    },
    {
      name: "Rekening",
      width: "200px",
      center: true,
      selector: (row) =>
        row.rekening.length === 0 ? "-" : row.rekening[0].namaRekening,
    },

    {
      name: "Tanggal Pengajuan",
      width: "200px",
      center: true,
      selector: (row) =>
        moments
          .tz(new Date(row.tglPengajuan), "Asia/Jakarta")
          .format("DD-MM-YYYY"),
    },
    {
      name: "Pembuat",
      width: "200px",
      center: true,
      selector: (row) => row.userPengajuan[0].namaLengkap,
    },
    {
      name: "Nominal Pengajuan",
      width: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.harga);
        return formattedValue;
      },
    },

    // {
    //   name: "Status Mesin Selesai",
    //   width: "200px",
    //   center: true,
    //   selector: (row) => row.totalOrders.Selesai,
    // },

    // {
    //   name: "Status Pembayaran",
    //   width: "200px",
    //   center: true,
    //   selector: (row) =>
    //     row.statusPembayaran === "" || !row.statusPembayaran ? (
    //       <span style={{ color: "rgb(251,59,70)" }}>
    //         <b>-</b>
    //       </span>
    //     ) : row.statusPembayaran === "Pending" ? (
    //       <div
    //         style={{
    //           backgroundColor: "rgba(255,163,47,0.2)",
    //           padding: "5px 10px 5px 10px",
    //           borderRadius: 5,
    //           border: "1px solid rgb(255,163,47)",
    //         }}
    //       >
    //         <b style={{ color: "rgb(255,163,47)" }}>Pending</b>
    //       </div>
    //     ) : row.statusPembayaran === "Proses" ? (
    //       <div
    //         style={{
    //           backgroundColor: "rgba(68,125,247,0.2)",
    //           padding: "5px 10px 5px 10px",
    //           borderRadius: 5,
    //           border: "1px solid rgb(68,125,247)",
    //         }}
    //       >
    //         <b style={{ color: "rgb(68,125,247)" }}>Proses</b>
    //       </div>
    //     ) : (
    //       <div
    //         style={{
    //           backgroundColor: "rgba(36,204,23,0.2)",
    //           padding: "5px 10px 5px 10px",
    //           borderRadius: 5,
    //           border: "1px solid rgb(36,204,23)",
    //         }}
    //       >
    //         <b style={{ color: "rgb(36,204,23)" }}>Lunas</b>
    //       </div>
    //     ),
    // },
    // {
    //   name: "Status Lunas",
    //   width: "200px",
    //   center: true,
    //   selector: (row) =>
    //     row.statusLunas === false ? (
    //       <span style={{ color: "rgb(251,59,70)" }}>
    //         <b>Belum Lunas</b>
    //       </span>
    //     ) : (
    //       <span style={{ color: "rgb(36,204,23)" }}>
    //         <b>Lunas</b>
    //       </span>
    //     ),
    // },

    // {
    //   name: "Kode Invoice",
    //   width: "200px",
    //   center: true,
    //   selector: (row) => row.invoices[row.invoices.length - 1].idInvoice,
    // },

    // {
    //   name: "Nama Instansi",
    //   width: "250px",
    //   selector: (row) => row.customerInstansi,
    // },
    // {
    //   name: "Nama Customer",
    //   width: "200px",
    //   selector: (row) => row.namaCustomer,
    // },
    // {
    //   name: "Order",
    //   width: "auto",
    //   selector: (row) => {
    //     return (
    //       <>
    //         <table cellPadding="4" border="1">
    //           <thead>
    //             <tr>
    //               <th>Kode</th>
    //               <th>Nama</th>
    //               <th>Kategori</th>
    //               <th>Sumber</th>
    //               <th>Qty</th>
    //               <th>Selesai</th>
    //             </tr>
    //           </thead>
    //           <tbody>
    //             {row.orders.map((val) => {
    //               return (
    //                 <>
    //                   <tr
    //                     style={{
    //                       backgroundColor:
    //                         val.qty != val.qtySelesai
    //                           ? "rgba(255, 3, 3,0.3)"
    //                           : null,
    //                     }}
    //                   >
    //                     <td>{val.produk.kodeProduk}</td>
    //                     <td>{val.produk.namaProduk}</td>
    //                     <td>
    //                       {val.produk.kategoriProduk === "upgrade"
    //                         ? "UPGRADE"
    //                         : "UTAMA"}
    //                     </td>
    //                     <td>{val.sumberProduk === "PL" ? "PL" : "SG"}</td>
    //                     <td>{val.qty} item</td>
    //                     <td style={{ textAlign: "center" }}>
    //                       {val.qtySelesai} item
    //                     </td>
    //                   </tr>
    //                 </>
    //               );

    //               // <li style={{ marginTop: 5 }}>
    //               //   <b>{val.produk.kodeProduk}</b> - {val.produk.namaProduk}
    //               //   {val.produk.kategoriProduk === "upgrade" ? " - UPGRADE" : null}
    //               //   {val.sumberProduk === "PL" ? (
    //               //     <b>
    //               //       {" "}
    //               //       <i style={{ color: "blue" }}> - PL</i>
    //               //     </b>
    //               //   ) : null}{" "}
    //               //   - <b>{val.qty} item</b>
    //               // </li>
    //             })}
    //           </tbody>
    //         </table>
    //       </>
    //     );
    //   },
    // },

    // {
    //   name: "Selesai Garansi",
    //   width: "200px",
    //   center: true,
    //   selector: (row) =>
    //     !row.selesaiGaransi
    //       ? "-"
    //       : moments
    //           .tz(new Date(row.selesaiGaransi), "Asia/Jakarta")
    //           .format("DD-MM-YYYY"),
    // },
    // {
    //   name: "Stock",
    //   width: "200px",
    //   center: true,
    //   selector: (row) => row.jumlahStock,
    // },
    // {
    //   name: "Minimal Stock",
    //   width: "200px",
    //   center: true,
    //   selector: (row) => row.minimalStock,
    // },
    // {
    //   name: "Tanggal Data Dibuat",
    //   width: "200px",
    //   center: true,
    //   selector: (row) =>
    //     moments
    //       .tz(new Date(row.createdAt), "Asia/Jakarta")
    //       .format("DD-MM-YYYY HH:mm:ss"),
    // },
    // {
    //   name: "Tanggal Update Data",
    //   width: "200px",
    //   center: true,
    //   selector: (row) =>
    //     moments
    //       .tz(new Date(row.updatedAt), "Asia/Jakarta")
    //       .format("DD-MM-YYYY HH:mm:ss"),
    // },
  ];

  const history = useHistory();
  React.useEffect(() => {
    let tmp =
      asset.listPengajuanAsset.data &&
      asset.listPengajuanAsset.data.map((val, index) => {
        return {
          ...val,
          // totalOrders: {
          //   Progress: val.orders.filter(
          //     (order) => order.statusProduk === "Progress"
          //   ).length,
          //   Selesai: val.orders.filter(
          //     (order) => order.statusProduk === "Selesai"
          //   ).length,
          // },
        };
      });

    setListPengajuanAsset(tmp);

    if (asset.listPengajuanAsset.length != 0) {
      setPending(false);
    }
  }, [asset.listPengajuanAsset]);

  React.useEffect(() => {
    if (searchText != "") {
      setPage(0);
    }
    getPengajuanAsset(dispatch, {
      cari: searchText,
      page: page + 1,
      limit: totalPerHalaman,
      tglAwal: moments
        .tz(new Date(tglAwal), "Asia/Jakarta")
        .format("YYYY-MM-DD"),
      tglAkhir: moments
        .tz(new Date(tglAkhir), "Asia/Jakarta")
        .format("YYYY-MM-DD"),
    });
  }, [page, totalPerHalaman, searchText, tglAwal, tglAkhir]);

  React.useEffect(() => {
    getKategoriAssetDropdown(dispatch);
    getLokasiAssetDropdown(dispatch);
    getRekeningDropdown(dispatch);
  }, []);

  React.useEffect(() => {
    let tmpKategori =
      kategoriAsset.listKategoriAssetDropdown.data &&
      kategoriAsset.listKategoriAssetDropdown.data.map((val, index) => {
        return {
          value: val.idKategoriAsset,
          label: `${val.namaKategori} - ${
            val.jenisBatas === "bawah" ? "Di Bawah" : "Di Atas"
          } ${new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 0,
          }).format(val.batas)}`,
        };
      });

    let tmpLokasi =
      lokasiAsset.listLokasiAssetDropdown.data &&
      lokasiAsset.listLokasiAssetDropdown.data.map((val, index) => {
        return {
          value: val.idLokasi,
          label: val.namaLokasi,
        };
      });

    let tmpRekening =
      pengajuanBiaya.rekeningDropdown.data &&
      pengajuanBiaya.rekeningDropdown.data.map((val, index) => {
        return {
          value: val.idRekening,
          label: val.namaRekening === "LAINNYA" ? "-" : val.namaRekening,
        };
      });

    setListKategoriAsset(tmpKategori);
    setListRekening(tmpRekening);
    setListLokasiAsset(tmpLokasi);
  }, [
    kategoriAsset.listKategoriAssetDropdown,
    lokasiAsset.listLokasiAssetDropdown,
    pengajuanBiaya.rekeningDropdown,
  ]);

  // const cekKodeSo = () => {
  //   cekSo(dispatch, { idSo: idSo })
  //     .then((response) => {
  //       if (response) {
  //         setStatusCekIdSo(response.data.cek === false ? 1 : 2);
  //       }
  //     })
  //     .catch((err) => {
  //
  //     });
  // };

  // const cekKodePo = () => {
  //   cekPo(dispatch, { idPo: idPo })
  //     .then((response) => {
  //       if (response) {
  //         setStatusCekIdPo(response.data.cek === false ? 1 : 2);
  //       }
  //     })
  //     .catch((err) => {
  //
  //     });
  // };

  const buatPengajuan = () => {
    // e.preventDefault();

    if (namaBarang === "") {
      setValidasiNamaBarang(true);
    }
    if (idKategori === "") {
      setValidasiKategori(true);
    }

    if (idLokasi === "") {
      setValidasiLokasi(true);
    }

    if (idRekeningAsal === "") {
      setValidasiRekeningAsal(true);
    }

    if (
      nominalPengajuan === 0 ||
      nominalPengajuan === undefined ||
      nominalPengajuan === "" ||
      nominalPengajuan === "0" ||
      isNaN(parseInt(nominalPengajuan))
    ) {
      setValidasiNominalPengajuan(true);
    }

    if (deskripsiPengajuan === "") {
      setValidasiDeskripsi(true);
    }

    if (dokumen.length === 0) {
      setDokumenValidasi(true);
    }
    if (
      namaBarang === "" ||
      idKategori === "" ||
      idLokasi === "" ||
      idRekeningAsal === "" ||
      nominalPengajuan === 0 ||
      nominalPengajuan === undefined ||
      nominalPengajuan === "" ||
      nominalPengajuan === "0" ||
      isNaN(parseInt(nominalPengajuan)) ||
      dokumen.length === 0
    ) {
      notify({
        code: 1,
        message: "Periksa kembali isian anda jangan ada yang salah atau kosong",
      });
    } else {
      Swal.fire({
        title: "Apakah anda yakin untuk melakukan pengajuan asset?",
        text: "Pastikan semua isian anda sudah benar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ajukan!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Loading...!",
            text: "Pengajuan asset sedang dikirim,tunggu proses hingga selesai!",
            icon: "warning",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          let formData = new FormData();
          formData.append("namaBarang", namaBarang);
          formData.append("idKategori", idKategori.value);
          formData.append("idLokasi", idLokasi.value);
          formData.append("idRekening", idRekeningAsal.value);
          formData.append("harga", nominalPengajuan);
          formData.append("deskripsiPengajuan", deskripsiPengajuan);
          formData.append(
            "tglPengajuan",
            moments.tz(new Date(), "Asia/Jakarta").format("YYYY-MM-DD")
          );
          dokumen.map((val, index) => {
            formData.append("dokumen", val);
          });
          tambahPengajuanAsset(formData).then((response) => {
            if (response.data.status === 200) {
              setModalAddPengajuan(false);
              setPending(false);
              setNamaBarang("");
              setIdKategori("");
              setValidasiKategori(false);
              setIdRekeningAsal("");
              setValidasiRekeningAsal(false);
              setNominalPengajuan(0);
              setValidasiNominalPengajuan(false);
              setDeskripsiPengajuan("");
              setValidasiDeskripsi(false);
              setDokumenValidasi(false);
              setDokumen([]);
              setUrlImage("");
              setDetailPengajuan(null);
              getPengajuanAsset(dispatch, {
                cari: searchText,
                page: page + 1,
                limit: totalPerHalaman,
                tglAwal: moments
                  .tz(new Date(tglAwal), "Asia/Jakarta")
                  .format("YYYY-MM-DD"),
                tglAkhir: moments
                  .tz(new Date(tglAkhir), "Asia/Jakarta")
                  .format("YYYY-MM-DD"),
              });
              Swal.fire({
                title: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              setPending(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: !response.data
                  ? "Gagal melakukan pengajuan"
                  : response.data.message,
              });
            }
          });
        }
      });
    }
  };

  const approvalPengajuan = () => {
    if (statusPengajuan === "") {
      setValidasiStatusPengajuan(true);
    }

    if (statusPengajuan.value === "Ditolak") {
      if (note === "") {
        setValidasiNote(true);
      }
    }

    if (
      statusPengajuan === "" ||
      (statusPengajuan.value === "Ditolak" && note === "")
    ) {
      notify({
        code: 1,
        message: "Periksa kembali isian anda jangan ada yang salah atau kosong",
      });
    } else {
      Swal.fire({
        title: "Apakah anda yakin untuk melakukan approval pengajuan asset?",
        text: "Pastikan semua isian anda sudah benar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Update!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Loading...!",
            text: "Approval pengajuan asset sedang diupdate,tunggu proses hingga selesai!",
            icon: "warning",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });

          let dataApproval = {
            // idPengajuan, note, statusPengajuan, kategori, pembagi,harga
            idPengajuan: detailPengajuan.idPengajuanAsset,
            note: note,
            statusPengajuan: statusPengajuan.value,
            kategori: detailPengajuan.kategoriAsset[0].kode,
            pembagi: detailPengajuan.kategoriAsset[0].pembagi,
            harga: detailPengajuan.harga,
            idRekening: detailPengajuan.rekening[0].idRekening,
          };

          approvePengajuanAsset(dataApproval).then((response) => {
            if (response.data.status === 200) {
              setModalEdit(false);
              setPending(false);
              setStatusPengajuan("");
              setValidasiStatusPengajuan(false);
              setNote("");
              setValidasiNote(false);
              setDetailPengajuan(null);
              getPengajuanAsset(dispatch, {
                cari: searchText,
                page: page + 1,
                limit: totalPerHalaman,
                tglAwal: moments
                  .tz(new Date(tglAwal), "Asia/Jakarta")
                  .format("YYYY-MM-DD"),
                tglAkhir: moments
                  .tz(new Date(tglAkhir), "Asia/Jakarta")
                  .format("YYYY-MM-DD"),
              });
              Swal.fire({
                title: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              setPending(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: !response.data
                  ? "Gagal melakukan approval"
                  : response.data.message,
              });
            }
          });
        }
      });
    }
  };
  // const updateSuratJalan = (e) => {
  //   e.preventDefault();
  //   if (statusBarang === "") {
  //     setStatusBarangValidasi(true);
  //   }
  //   if (dokumen.length === 0) {
  //     setDokumenValidasi(true);
  //   }

  //   if (
  //     dokumen.length === 0 ||
  //     statusBarang === ""
  //     // (statusBarang.value === "Pending" && namaEkspedisi === "")
  //   ) {
  //     notify({
  //       code: 1,
  //       message: "Periksa kembali isian anda jangan ada yang salah atau kosong",
  //     });
  //   } else {
  //     Swal.fire({
  //       title: "Apakah anda yakin untuk mengupdate status surat jalan?",
  //       text: "Pastikan semua isian anda sudah benar!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Submit!",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         Swal.fire({
  //           title: "Loading...!",
  //           text: "Update data sedang dilakukan,tunggu proses hingga selesai!",
  //           icon: "warning",
  //           allowOutsideClick: false,
  //           didOpen: () => {
  //             Swal.showLoading();
  //           },
  //         });
  //         let formData = new FormData();
  //         formData.append("idSo", idSo);
  //         formData.append("idSj", idSj);
  //         formData.append("statusBarang", statusBarang.value);
  //         formData.append("namaEkspedisi", namaEkspedisi);
  //         formData.append("alamatEkspedisi", alamatEkspedisi);
  //         formData.append("telpEkspedisi", telpEkspedisi);
  //         formData.append("namaPenerima", namaPenerima);
  //         formData.append("alamatPenerima", alamatPenerima);
  //         formData.append("noTelpPenerima", noHpPenerima);
  //         formData.append("nomorResi", nomorResi);
  //         formData.append("keterangan", keterangan);
  //         dokumen.map((val, index) => {
  //           formData.append("dokumen", val);
  //         });
  //         // let data = {
  //         //   idSo: idSo,
  //         //   idSj: idSj,
  //         //   statusBarang: statusBarang.value,
  //         //   namaEkspedisi: namaEkspedisi,
  //         //   alamatEkspedisi: alamatEkspedisi,
  //         //   telpEkspedisi: telpEkspedisi,
  //         //   namaPenerima: namaPenerima,
  //         //   alamatPenerima: alamatPenerima,
  //         //   noTelpPenerima: noHpPenerima,
  //         //   nomorResi: nomorResi,
  //         //   keterangan: keterangan,
  //         // };

  //         editStatusSj(formData).then((response) => {
  //           if (response.data.status === 200) {
  //             setModalEditSj(false);
  //             setPending(false);
  //             setNamaEkspedisi("");

  //             setStatusBarang("");
  //             setIdSj("");
  //             setStatusBarangValidasi(false);
  //             setIdSo("");
  //             setAlamatEkspedisi("");
  //             setTelpEkspedisi("");
  //             setNamaPenerima("");
  //             setAlamatPenerima("");
  //             setNoHpPenerima("");
  //             setDokumen([]);
  //             setDokumenValidasi(false);
  //             getInvSj(dispatch, {
  //               cari: searchText,
  //               page: page + 1,
  //               limit: totalPerHalaman,
  //               tglAwal: moments
  //                 .tz(new Date(tglAwal), "Asia/Jakarta")
  //                 .format("YYYY-MM-DD"),
  //               tglAkhir: moments
  //                 .tz(new Date(tglAkhir), "Asia/Jakarta")
  //                 .format("YYYY-MM-DD"),
  //             });
  //             Swal.fire({
  //               title: response.data.message,
  //               icon: "success",
  //               showConfirmButton: false,
  //               timer: 1500,
  //             });
  //           } else {
  //             setPending(false);
  //             Swal.fire({
  //               icon: "error",
  //               title: "Oops...",
  //               text: !response.data
  //                 ? "Gagal menambahkan Produk"
  //                 : response.data.message,
  //             });
  //           }
  //         });
  //       }
  //     });
  //   }
  // };

  const notify = (val) => {
    var type = val.code === 0 ? "info" : "danger";

    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <div>
            <b style={{ color: "#000000" }}>{val.message}</b>
          </div>
        </div>
      ),
      type: type,
      icon: val.code === 0 ? "fas fa-box-open" : "fas fa-times-circle",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <Row
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <Col>
            <div style={{ fontSize: 25, fontWeight: "bold" }}>
              Pengajuan Asset
            </div>
          </Col>
          <Col style={{ textAlign: "right" }}>
            {/* <Button
              style={{ marginLeft: 20, marginBottom: 0 }}
              onClick={() => {
                setModal(true);
              }}
            >
              Tambah Produk
            </Button> */}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div>
                <InputGroup>
                  <Form.Control
                    style={{
                      paddingLeft: "10px",
                      width: "300px",
                    }}
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="ID Pengajuan"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      style={{ cursor: "pointer" }}
                      onClick={handleClearSearch}
                    >
                      <i class="fas fa-times"></i>
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </div>
          </Col>
        </Row>
        <Col md="12">
          <Row>
            <Col md="2">
              <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                Tanggal Awal
              </div>
              <Form.Group
                style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
              >
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Date Picker Here",
                    readOnly: true,
                    style: {
                      cursor: "default",
                      color: "black",
                      backgroundColor: "white",
                    },
                  }}
                  onChange={(e) => {
                    e.toDate().setHours(0, 0, 0, 0),
                      setTglAwal(e.toDate().setHours(0, 0, 0, 0));
                  }}
                  value={tglAwal}
                  initialValue={tglAwal}
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                ></ReactDatetime>
              </Form.Group>
            </Col>
            <Col md="2">
              <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                Tanggal Akhir
              </div>
              <Form.Group
                style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
              >
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Date Picker Here",
                    readOnly: true,
                    style: {
                      cursor: "default",
                      color: "black",
                      backgroundColor: "white",
                    },
                  }}
                  onChange={(e) => {
                    e.toDate().setHours(23, 59, 0, 0),
                      setTglAkhir(e.toDate().setHours(23, 59, 0, 0));
                  }}
                  value={tglAkhir}
                  initialValue={tglAkhir}
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                ></ReactDatetime>
              </Form.Group>
            </Col>
            <Col md="8" style={{ textAlign: "right" }}>
              <Button
                style={{
                  marginLeft: 20,
                  marginBottom: 0,
                  backgroundColor: "rgba(68,125,247,1)",
                  borderColor: "rgba(68,125,247,1)",
                }}
                onClick={() => {
                  // exportAllSj();
                  setModalAddPengajuan(true);
                }}
              >
                <i className="fas fa-plus" style={{ marginRight: 10 }}></i>
                Buat Pengajuan
              </Button>
            </Col>
          </Row>
        </Col>
        {/* <Row>
          <Col>
            <Button
              onClick={() => {
                setModalImport(true);
              }}
              className="btn-social"
              variant="twitter"
              style={{
                backgroundColor: "rgba(40,118,74,1)",
                borderColor: "rgba(40,118,74,1)",
              }}
            >
              <i className="fas fa-file-excel" style={{ marginRight: 10 }}></i>
              Import Excel
            </Button>
            <Button
              onClick={() => {
                window.open(
                  "https://docs.google.com/spreadsheets/d/1Qxfk5Xe0Xbjr39htdHLRoiA6_wSCEWaN/edit?usp=sharing&ouid=116475921970373937456&rtpof=true&sd=true",
                  "_blank"
                );
              }}
              className="btn-social"
              variant="twitter"
              style={{
                backgroundColor: "rgba(64,123,255,1)",
                borderColor: "rgba(64,123,255,1)",
                marginLeft: 10,
              }}
            >
              <i
                className="fas fa-cloud-download-alt"
                style={{ marginRight: 10 }}
              ></i>
              Download Format Import
            </Button>
          </Col>
          <Col>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div>
                <InputGroup>
                  <Form.Control
                    style={{
                      paddingLeft: "10px",
                      width: "300px",
                    }}
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Nama Mesin/Kode Mesin"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      style={{ cursor: "pointer" }}
                      onClick={handleClearSearch}
                    >
                      <i class="fas fa-times"></i>
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </div>
          </Col>
        </Row> */}

        <Row>
          <Col md="12">
            <Card>
              <Card.Body>
                <DataTable
                  columns={columns}
                  data={listPengajuanAsset}
                  //   pagination
                  customStyles={customStyles}
                  paginationPerPage={totalPerHalaman}
                  //   onChangePage={handlePageChange}
                  progressPending={pending}
                />

                <div style={{ marginTop: 35 }}>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col>
                      <Row style={{ alignItems: "center" }}>
                        <div
                          style={{
                            paddingRight: 0,
                            marginLeft: 20,
                            marginRight: 10,
                          }}
                        >
                          <label
                            style={{
                              fontWeight: "bold",
                              textTransform: "none",
                              fontSize: 14,
                            }}
                          >
                            Rows per page
                          </label>
                        </div>
                        <Col sm="3" style={{ paddingLeft: 0 }}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="Pilih Client"
                            value={options.find(
                              (option) => option.value === totalPerHalaman
                            )}
                            onChange={handlePageSizeChange}
                            options={options}
                            placeholder="Rows per page"
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col>
                      <ReactPaginate
                        forcePage={page}
                        containerClassName="paginations justify-content-end"
                        previousClassName="pages-items-containers"
                        previousLinkClassName="pages-links-labels"
                        nextClassName="pages-items-containers"
                        nextLinkClassName="pages-links-labels"
                        breakClassName="pages-items-containers"
                        pageCount={asset.listPengajuanAsset.totalPages}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        pageLinkClassName="pages-links-labels"
                        pageClassName="pages-items-containers"
                        activeClassName="actives"
                        activeLinkClassName="activess"
                        nextLabel=" > "
                        previousLabel=" < "
                        initialPage={page}
                        disableInitialCallback={true}
                        onPageChange={(val) => {
                          setPage(val.selected);
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Modal Import Produk */}
      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
          setDetailPengajuan(null);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Detail Pengajuan
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  ID Pengajuan
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPengajuan && detailPengajuan.idPengajuanAsset}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Nama Barang
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPengajuan && detailPengajuan.namaBarang}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Kategori
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPengajuan &&
                    detailPengajuan.kategoriAsset[0].namaKategori}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Lokasi
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPengajuan && detailPengajuan.lokasiAsset[0].namaLokasi}
                </Col>
              </Row>

              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Nominal Pengajuan
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPengajuan &&
                    new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      minimumFractionDigits: 0,
                    }).format(detailPengajuan.harga)}
                </Col>
              </Row>

              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Rekening
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPengajuan && detailPengajuan.rekening[0].namaRekening}
                </Col>
              </Row>

              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Pembuat
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPengajuan &&
                    detailPengajuan.userPengajuan[0].namaLengkap}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Tanggal Pengajuan
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPengajuan &&
                    moments
                      .tz(
                        new Date(detailPengajuan.tglPengajuan),
                        "Asia/Jakarta"
                      )
                      .format("DD-MM-YYYY")}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Deskripsi Pengajuan
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPengajuan && detailPengajuan.deskripsiPengajuan}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Approval
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPengajuan && detailPengajuan.userApprove.length === 0
                    ? "-"
                    : detailPengajuan &&
                      detailPengajuan.userApprove[0].namaLengkap}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Tanggal Approve
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPengajuan && !detailPengajuan.tglApprove
                    ? "-"
                    : detailPengajuan &&
                      moments
                        .tz(
                          new Date(detailPengajuan.tglApprove),
                          "Asia/Jakarta"
                        )
                        .format("DD-MM-YYYY")}
                </Col>
              </Row>

              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Status Pengajuan
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPengajuan &&
                  detailPengajuan.statusPengajuan === "Menunggu" ? (
                    <b style={{ color: "rgb(255,163,47)" }}>Menunggu</b>
                  ) : detailPengajuan &&
                    detailPengajuan.statusPengajuan === "Ditolak" ? (
                    <b style={{ color: "rgb(251,59,70)" }}>Ditolak</b>
                  ) : (
                    <b style={{ color: "rgb(36,204,239)" }}>Disetujui</b>
                  )}
                </Col>
              </Row>

              <div style={{ marginTop: 20 }}>
                <b>Bukti Dokumen Pengajuan</b>
              </div>
              <hr></hr>
              <Row>
                {detailPengajuan &&
                  detailPengajuan.buktiFotoPengajuan.map((val, index) => {
                    return (
                      <>
                        <Col sm="4">
                          <Image
                            onClick={() => {
                              setUrlImage(val);
                              setModalImage(true);
                            }}
                            src={val}
                            style={{
                              objectFit: "contain",
                              width: "200px",
                              height: "300px",
                              cursor: "pointer",
                            }}
                            thumbnail
                          />
                        </Col>
                      </>
                    );
                  })}
              </Row>

              {/* <div style={{ marginTop: 20 }}>
                <b>Bukti Dokumen Approval</b>
              </div>
              <hr></hr>
              <Row>
                {detailPengajuan &&
                detailPengajuan.buktiFotoApprove.length === 0 ? (
                  <span style={{ color: "grey", marginLeft: 20 }}>
                    Belum memiliki dokumen approval.
                  </span>
                ) : (
                  detailPengajuan &&
                  detailPengajuan.buktiFotoApprove.map((val, index) => {
                    return (
                      <>
                        <Col sm="4">
                          <Image
                            onClick={() => {
                              setUrlImage(val);
                              setModalImage(true);
                            }}
                            src={val}
                            style={{
                              objectFit: "contain",

                              width: "200px",
                              height: "300px",
                              cursor: "pointer",
                            }}
                            thumbnail
                          />
                        </Col>
                      </>
                    );
                  })
                )}
              </Row> */}
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      {/* Modal Add Pengajuan Biaya */}
      <Modal
        size="md"
        show={modalAddPengajuan}
        onHide={() => {
          setModalAddPengajuan(false);
          setPending(false);
          setNamaBarang("");
          setIdKategori("");
          setValidasiKategori(false);
          setIdRekeningAsal("");
          setValidasiRekeningAsal(false);
          setNominalPengajuan(0);
          setValidasiNominalPengajuan(false);
          setDeskripsiPengajuan("");
          setValidasiDeskripsi(false);
          setDokumenValidasi(false);
          setDokumen([]);
          setUrlImage("");
          setDetailPengajuan(null);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Form Buat Pengajuan Asset
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Nama Barang <span className="star">*</span>
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setNamaBarang(e.target.value);
                      setValidasiNamaBarang(false);
                    }}
                    placeholder="Nama Barang"
                    value={namaBarang}
                  ></Form.Control>
                  {validasiNamaBarang === false ? null : (
                    <label
                      className="error"
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Nama barang tidak boleh kosong.
                    </label>
                  )}
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Pilih Kategori Asset<span className="star">*</span>
                  </label>

                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={idKategori}
                    onChange={(value) => {
                      setIdKategori(value);
                      setValidasiKategori(false);
                    }}
                    options={listKategoriAsset}
                    placeholder="Pilih Kategori"
                  />
                  {validasiKategori === false ? null : (
                    <label
                      className="error"
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Kategori asset tidak boleh kosong.
                    </label>
                  )}
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Pilih Lokasi Asset<span className="star">*</span>
                  </label>

                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={idLokasi}
                    onChange={(value) => {
                      setIdLokasi(value);
                      setValidasiLokasi(false);
                    }}
                    options={listLokasiAsset}
                    placeholder="Pilih Lokasi"
                  />
                  {validasiLokasi === false ? null : (
                    <label
                      className="error"
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Lokasi asset tidak boleh kosong.
                    </label>
                  )}
                </Form.Group>

                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Pilih Rekening (Pengeluaran)
                    <span className="star">*</span>
                  </label>

                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={idRekeningAsal}
                    onChange={(value) => {
                      setIdRekeningAsal(value);
                      setValidasiRekeningAsal(false);
                    }}
                    options={listRekening}
                    placeholder="Pilih Rekening Asal"
                  />
                  {validasiRekeningAsal === false ? null : (
                    <label
                      className="error"
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Rekening tidak boleh kosong.
                    </label>
                  )}
                </Form.Group>

                <Form.Group style={{ marginTop: 15 }}>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Harga Asset <span className="star">*</span>
                  </label>
                  <CurrencyInput
                    className="custom-input"
                    style={{
                      padding: 5,
                      borderRadius: 4,
                      width: "100%",
                      height: "40px",
                      borderWidth: "1px",
                      borderTopColor: "rgba(227, 227, 227, 0.3)",
                      borderLeftColor: "rgba(227, 227, 227, 0.3)",
                      borderBottomColor: "rgba(227, 227, 227, 1)",
                      borderRightColor: "rgba(227, 227, 227, 1)",
                    }}
                    name="nominal"
                    placeholder="Nominal Pengajuan"
                    prefix="Rp "
                    onValueChange={(value) => {
                      setNominalPengajuan(value);
                      setValidasiNominalPengajuan(false);
                    }}
                    defaultValue={nominalPengajuan}
                  />
                  {validasiNominalPengajuan === false ? null : (
                    <label
                      className="error"
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Harga asset tidak boleh kosong.
                    </label>
                  )}
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Deskripsi <span className="star">*</span>
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setDeskripsiPengajuan(e.target.value);
                      setValidasiDeskripsi(false);
                    }}
                    placeholder="Deskripsi Pengajuan"
                    value={deskripsiPengajuan}
                  ></Form.Control>
                  {validasiDeskripsi === false ? null : (
                    <label
                      className="error"
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Deskripsi tidak boleh kosong.
                    </label>
                  )}
                </Form.Group>

                <div>
                  <div>
                    <label style={{ color: "black", fontWeight: "bold" }}>
                      Upload Dokumen{" "}
                      <span className="star" style={{ textTransform: "none" }}>
                        *{" "}
                        <span style={{ color: "blue" }}>
                          (Maksimal 2 Dokumen)
                        </span>
                      </span>
                    </label>
                  </div>

                  <label
                    className="label-input-file btn text-white mb-4"
                    htmlFor="import2"
                    style={{
                      fontWeight: "bold",
                      backgroundColor:
                        dokumen.length === 2 ? "grey" : "#447DF7",
                      border: 0,
                      marginTop: 10,
                    }}
                  >
                    <div
                      style={{
                        color: "#FFFFFF",
                        textTransform: "none",
                      }}
                    >
                      <i
                        className="fas fa-images"
                        style={{ marginRight: 5 }}
                      ></i>
                      Pilih File
                    </div>

                    <input
                      disabled={pending || dokumen.length === 2 ? true : false}
                      type="file"
                      id="import2"
                      onClick={(e) => {
                        e.target.value = "";
                      }}
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      accept=".jpg,.jpeg,.png"
                      multiple
                    />
                  </label>
                  {dokumenValidasi === false ? null : (
                    <label
                      className="error"
                      style={{
                        textTransform: "none",
                        color: "red",
                        marginLeft: 10,
                      }}
                    >
                      Dokumen tidak boleh kosong.
                    </label>
                  )}
                  {dokumen.length === 0 ? (
                    <div>
                      <p style={{ color: "GrayText" }}>File kosong</p>
                    </div>
                  ) : (
                    dokumen.map((file, index) => (
                      <div className="d-flex align-items-center" key={index}>
                        <p className="mb-1 mr-1">{file.name}</p>

                        <i
                          className="fa fa-times"
                          style={{
                            cursor: "pointer",
                            color: "red",
                            marginLeft: 10,
                          }}
                          onClick={() =>
                            pending ? null : removeDokumen(index)
                          }
                        />
                      </div>
                    ))
                  )}
                </div>
              </Form>
            </Card.Body>
            <Card.Footer style={{ textAlign: "end" }}>
              <Button
                className="btn-fill"
                type="submit"
                // variant="info"
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={(e) => {
                  e.preventDefault();
                  buatPengajuan();
                }}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      {/* Modal Edit Surat Jalan */}
      <Modal
        size="md"
        show={modalEdit}
        onHide={() => {
          setModalEdit(false);
          setPending(false);
          setStatusPengajuan("");
          setValidasiStatusPengajuan(false);
          setNote("");
          setValidasiNote(false);
          setDetailPengajuan(null);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Update Pengajuan
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Status Pengajuan <span className="star">*</span>
                  </label>

                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={statusPengajuan}
                    onChange={(value) => {
                      setStatusPengajuan(value);
                      setValidasiStatusPengajuan(false);
                    }}
                    options={[
                      { value: "Ditolak", label: "Ditolak" },
                      { value: "Disetujui", label: "Disetujui" },
                    ]}
                    placeholder="Pilih Status Pengajuan"
                  />
                  {validasiStatusPengajuan === false ? null : (
                    <label
                      className="error"
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Status pengajuan tidak boleh kosong.
                    </label>
                  )}
                </Form.Group>
                {statusPengajuan.value === "Disetujui" ||
                statusPengajuan === "" ? null : (
                  <Form.Group>
                    <label style={{ color: "black", fontWeight: "bold" }}>
                      Keterangan
                    </label>
                    <Form.Control
                      onChange={(e) => {
                        setNote(e.target.value);
                        setValidasiNote(false);
                      }}
                      placeholder="Keterangan"
                      type="text"
                      value={note}
                    ></Form.Control>
                    {validasiNote === true &&
                    statusPengajuan.value === "Ditolak" ? (
                      <label
                        className="error"
                        style={{ textTransform: "none", color: "red" }}
                      >
                        Status pengajuan tidak boleh kosong.
                      </label>
                    ) : null}
                  </Form.Group>
                )}
              </Form>
            </Card.Body>
            <Card.Footer style={{ textAlign: "end" }}>
              <Button
                className="btn-fill"
                type="submit"
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={(e) => {
                  e.preventDefault();
                  approvalPengajuan();
                }}
              >
                Update
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      {/* Modal show image */}
      <Modal
        size="lg"
        show={modalImage}
        onHide={() => {
          setModalImage(false);
          setUrlImage("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Detail Gambar
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Image
                src={urlImage}
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                }}
                thumbnail
              />
            </Card.Body>
          </Card>
        </Col>
      </Modal>
    </>
  );
}

export default PengajuanAsset;
