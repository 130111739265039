const initialState = {
  listPengajuanAsset: [],
  listAsset: [],
  nominalAsset: null,
};

const assetReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_ALLPENGAJUANASSET":
      return {
        ...state,
        listPengajuanAsset: data,
      };
    case "SET_ALLASSET":
      return {
        ...state,
        listAsset: data,
      };
    case "SET_TOTALNOMINALASSET":
      return {
        ...state,
        nominalAsset: data,
      };
    // case "SET_KEBUTUHANDROPDOWN":
    //   return {
    //     ...state,
    //     kebutuhanDropdown: data,
    //   };

    default:
      return state;
  }
};
export default assetReducer;
