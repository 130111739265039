/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Buttons from "views/Components/Buttons.js";
import GridSystem from "views/Components/GridSystem.js";
import Panels from "views/Components/Panels.js";
import SweetAlert from "views/Components/SweetAlertPage.js";
import Notifications from "views/Components/Notifications.js";
import Icons from "views/Components/Icons.js";
import Typography from "views/Components/Typography.js";
import RegularForms from "views/Forms/RegularForms.js";
import ExtendedForms from "views/Forms/ExtendedForms.js";
import ValidationForms from "views/Forms/ValidationForms.js";
import Wizard from "views/Forms/Wizard/Wizard.js";
import RegularTables from "views/Tables/RegularTables.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import ReactTables from "views/Tables/ReactTables.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import VectorMap from "views/Maps/VectorMap.js";
import Charts from "views/Charts.js";
import Calendar from "views/Calendar.js";
import UserPage from "views/Pages/UserPage.js";

import LoginPage from "views/Pages/LoginPage.js";
import Dashboards from "views/Pages/Dashboard";
import Divisi from "views/Pages/Karyawan/Divisi";
import Jabatan from "views/Pages/Karyawan/Jabatan";
import Role from "views/Pages/Karyawan/Role";
import Karyawan from "views/Pages/Karyawan/ListKaryawan";
import Produk from "views/Pages/Produk/Produk";
import CardProduk from "views/Pages/CardProduk/CardProduk";
import SalesOrder from "views/Pages/SalesOrder/SalesOrder";
import InvoiceOrder from "views/Pages/InvoiceOrder/InvoiceOrder";
import SuratJalan from "views/Pages/SuratJalan/SuratJalan";
import PesanLangsung from "views/Pages/PesanLangsung/PesanLangsung";
import Po from "views/Pages/Po/Po";
import Kebutuhan from "views/Pages/Kebutuhan/Kebutuhan";
import Rekening from "views/Pages/Rekening/Rekening";
import PengajuanBiaya from "views/Pages/Pengajuan/Pengajuan";
import SimpananRekening from "views/Pages/SimpananRekening/SimpananRekening";
import KategoriAsset from "views/Pages/Asset/KategoriAsset";
import LokasiAsset from "views/Pages/Asset/LokasiAsset";
import PengajuanAsset from "views/Pages/Asset/PengajuanAsset";
import ListAsset from "views/Pages/Asset/ListAsset";

var routes = [
  {
    show: true,
    path: "/dashboards",
    layout: "/admin",
    name: "Dashboard",
    icon: "fas fa-chart-pie",
    component: Dashboards,
    group: "Dashboard",
  },
  {
    show: true,
    path: "/karyawan",
    layout: "/admin",
    name: "Karyawan",
    icon: "fas fa-users",
    component: Karyawan,
    group: "Karyawan",
  },
  {
    show: true,
    path: "/produk",
    layout: "/admin",
    name: "Produk",
    icon: "fas fa-box-open",
    component: Produk,
    group: "Produk",
  },
  {
    show: true,
    path: "/cardproduk",
    layout: "/admin",
    name: "Produk Card",
    icon: "fas fa-box-open",
    component: CardProduk,
    group: "Produk Card",
  },
  {
    show: true,
    path: "/salesorder",
    layout: "/admin",
    name: "Sales Order",
    icon: "fas fa-clipboard-list",
    component: SalesOrder,
    group: "Sales Order",
  },
  {
    show: true,
    path: "/invoiceorder",
    layout: "/admin",
    name: "Invoice Order",
    icon: "fas fa-file-invoice-dollar",
    component: InvoiceOrder,
    group: "Invoice",
  },
  {
    show: true,
    path: "/pesanlangsung",
    layout: "/admin",
    name: "Pesan Langsung",
    icon: "fas fa-people-carry",
    component: PesanLangsung,
    group: "Pesan Langsung",
  },
  {
    show: true,
    path: "/po",
    layout: "/admin",
    name: "Purchase Order",
    icon: "fas fa-store",
    component: Po,
    group: "Purchase Order",
  },
  {
    show: true,
    path: "/suratjalan",
    layout: "/admin",
    name: "Surat Jalan",
    icon: "fas fa-envelope-open-text",
    component: SuratJalan,
    group: "Surat Jalan",
  },
  {
    show: true,
    collapse: true,
    path: "/asset",
    name: "Asset",
    state: "openAsset",
    icon: "fas fa-archive",
    group: "Asset",
    views: [
      {
        show: true,
        path: "/pengajuanAsset",
        layout: "/admin",
        name: "Pengajuan Asset",
        mini: "PA",
        // icon: "fas fa-house-user",
        component: PengajuanAsset,
        group: "Asset",
      },
      {
        show: true,
        path: "/listAsset",
        layout: "/admin",
        name: "List Asset",
        mini: "LA",
        // icon: "fas fa-house-user",
        component: ListAsset,
        group: "Asset",
      },
      // {
      //   show: true,
      //   path: "/lokasiAsset",
      //   layout: "/admin",
      //   name: "Lokasi Asset",
      //   mini: "LA",
      //   // icon: "fas fa-house-user",
      //   component: LokasiAsset,
      //   group: "Asset",
      // },
      // {
      //   show: true,
      //   path: "/jabatan",
      //   layout: "/admin",
      //   name: "Jabatan",
      //   mini: "J",
      //   // icon: "fas fa-user-tie",
      //   component: Jabatan,
      //   group: "Setting",
      // },
      // {
      //   show: true,
      //   path: "/role",
      //   layout: "/admin",
      //   name: "Role",
      //   mini: "R",
      //   // icon: "fas fa-user-cog",
      //   component: Role,
      //   group: "Setting",
      // },
    ],
  },

  {
    show: true,
    path: "/pengajuanBiaya",
    layout: "/admin",
    name: "Mutasi Rekening",
    icon: "fas fa-receipt",
    component: PengajuanBiaya,
    group: "Pengajuan",
  },
  {
    show: true,
    path: "/simpananRekening",
    layout: "/admin",
    name: "Simpanan Rekening",
    icon: "fas fa-receipt",
    component: SimpananRekening,
    group: "Simpanan",
  },

  {
    show: true,
    path: "/kebutuhan",
    layout: "/admin",
    name: "Kebutuhan",
    icon: "fas fa-list",
    component: Kebutuhan,
    group: "Kebutuhan",
  },
  {
    show: true,
    path: "/rekening",
    layout: "/admin",
    name: "Rekening",
    icon: "far fa-credit-card",
    component: Rekening,
    group: "Rekening",
  },
  {
    show: true,
    collapse: true,
    path: "/setting",
    name: "Setting",
    state: "openSetting",
    icon: "fas fa-cogs",
    group: "Setting",
    views: [
      {
        show: true,
        path: "/divisi",
        layout: "/admin",
        name: "Divisi",
        mini: "D",
        // icon: "fas fa-house-user",
        component: Divisi,
        group: "Setting",
      },
      {
        show: true,
        path: "/jabatan",
        layout: "/admin",
        name: "Jabatan",
        mini: "J",
        // icon: "fas fa-user-tie",
        component: Jabatan,
        group: "Setting",
      },
      {
        show: true,
        path: "/role",
        layout: "/admin",
        name: "Role",
        mini: "R",
        // icon: "fas fa-user-cog",
        component: Role,
        group: "Setting",
      },
      {
        show: true,
        path: "/kategoriAsset",
        layout: "/admin",
        name: "Kategori Asset",
        mini: "KA",
        // icon: "fas fa-house-user",
        component: KategoriAsset,
        group: "Setting",
      },
      {
        show: true,
        path: "/lokasiAsset",
        layout: "/admin",
        name: "Lokasi Asset",
        mini: "LA",
        // icon: "fas fa-house-user",
        component: LokasiAsset,
        group: "Setting",
      },
    ],
  },
  {
    show: false,
    path: "/userprofile",
    layout: "/admin",
    name: "User Profile",
    icon: "fas fa-envelope-open-text",
    component: UserPage,
  },
  // {
  //   show: true,
  //   group: "Setting",
  //   path: "/dashboard",
  //   layout: "/admin",
  //   name: "Dashboard",
  //   icon: "nc-icon nc-chart-pie-35",
  //   component: Dashboard,
  // },
  // {
  //   show: true,
  //   group: "Setting",
  //   collapse: true,
  //   path: "/components",
  //   name: "Components",
  //   state: "openComponents",
  //   icon: "nc-icon nc-app",
  //   views: [
  //     {
  //       show: true,
  //       group: "Setting",
  //       path: "/buttons",
  //       layout: "/admin",
  //       name: "Buttons",
  //       mini: "B",
  //       component: Buttons,
  //     },
  //     {
  //       show: true,
  //       group: "Setting",
  //       path: "/grid-system",
  //       layout: "/admin",
  //       name: "Grid System",
  //       mini: "GS",
  //       component: GridSystem,
  //     },
  //     {
  //       show: true,
  //       group: "Setting",
  //       path: "/panels",
  //       layout: "/admin",
  //       name: "Panels",
  //       mini: "P",
  //       component: Panels,
  //     },
  //     {
  //       show: true,
  //       group: "Setting",
  //       path: "/sweet-alert",
  //       layout: "/admin",
  //       name: "Sweet Alert",
  //       mini: "SA",
  //       component: SweetAlert,
  //     },
  //     {
  //       show: true,
  //       group: "Setting",
  //       path: "/notifications",
  //       layout: "/admin",
  //       name: "Notifications",
  //       mini: "N",
  //       component: Notifications,
  //     },
  //     {
  //       show: true,
  //       group: "Setting",
  //       path: "/icons",
  //       layout: "/admin",
  //       name: "Icons",
  //       mini: "I",
  //       component: Icons,
  //     },
  //     {
  //       show: true,
  //       group: "Setting",
  //       path: "/typography",
  //       layout: "/admin",
  //       name: "Typography",
  //       mini: "T",
  //       component: Typography,
  //     },
  //   ],
  // },
  // {
  //   show: true,
  //   group: "Setting",
  //   collapse: true,
  //   path: "/forms",
  //   name: "Forms",
  //   state: "openForms",
  //   icon: "nc-icon nc-notes",
  //   views: [
  //     {
  //       show: true,
  //       group: "Setting",
  //       path: "/regular-forms",
  //       layout: "/admin",
  //       name: "Regular Forms",
  //       mini: "RF",
  //       component: RegularForms,
  //     },
  //     {
  //       show: true,
  //       group: "Setting",
  //       path: "/extended-forms",
  //       layout: "/admin",
  //       name: "Extended Forms",
  //       mini: "EF",
  //       component: ExtendedForms,
  //     },
  //     {
  //       show: true,
  //       group: "Setting",
  //       path: "/validation-forms",
  //       layout: "/admin",
  //       name: "Validation Forms",
  //       mini: "VF",
  //       component: ValidationForms,
  //     },
  //     {
  //       show: true,
  //       group: "Setting",
  //       path: "/wizard",
  //       layout: "/admin",
  //       name: "Wizard",
  //       mini: "W",
  //       component: Wizard,
  //     },
  //   ],
  // },
  // {
  //   show: true,
  //   group: "Setting",
  //   collapse: true,
  //   path: "/tables",
  //   name: "Tables",
  //   state: "openTables",
  //   icon: "nc-icon nc-paper-2",
  //   views: [
  //     {
  //       show: true,
  //       group: "Setting",
  //       path: "/regular-tables",
  //       layout: "/admin",
  //       name: "Regular Tables",
  //       mini: "RT",
  //       component: RegularTables,
  //     },
  //     {
  //       show: true,
  //       group: "Setting",
  //       path: "/extended-tables",
  //       layout: "/admin",
  //       name: "Extended Tables",
  //       mini: "ET",
  //       component: ExtendedTables,
  //     },
  //     {
  //       show: true,
  //       group: "Setting",
  //       path: "/react-table",
  //       layout: "/admin",
  //       name: "React Table",
  //       mini: "RT",
  //       component: ReactTables,
  //     },
  //   ],
  // },
  // {
  //   show: true,
  //   group: "Setting",
  //   collapse: true,
  //   path: "/maps",
  //   name: "Maps",
  //   state: "openMaps",
  //   icon: "nc-icon nc-pin-3",
  //   views: [
  //     {
  //       show: true,
  //       group: "Setting",
  //       path: "/google-maps",
  //       layout: "/admin",
  //       name: "Google Maps",
  //       mini: "GM",
  //       component: GoogleMaps,
  //     },
  //     {
  //       show: true,
  //       group: "Setting",
  //       path: "/full-screen-maps",
  //       layout: "/admin",
  //       name: "Full Screen Map",
  //       mini: "FSM",
  //       component: FullScreenMap,
  //     },
  //     {
  //       show: true,
  //       group: "Setting",
  //       path: "/vector-maps",
  //       layout: "/admin",
  //       name: "Vector Map",
  //       mini: "VM",
  //       component: VectorMap,
  //     },
  //   ],
  // },
  // {
  //   show: true,
  //   group: "Setting",
  //   path: "/charts",
  //   layout: "/admin",
  //   name: "Charts",
  //   icon: "nc-icon nc-chart-bar-32",
  //   component: Charts,
  // },
  // {
  //   show: true,
  //   group: "Setting",
  //   path: "/calendar",
  //   layout: "/admin",
  //   name: "Calendar",
  //   icon: "nc-icon nc-single-copy-04",
  //   component: Calendar,
  // },
  {
    show: false,
    collapse: true,
    path: "/pages",
    name: "Pages",
    state: "openPages",
    icon: "nc-icon nc-puzzle-10",
    views: [
      // {
      //   path: '/user-page',
      //   layout: '/admin',
      //   name: 'User Page',
      //   mini: 'UP',
      //   component: UserPage,
      // },
      {
        show: false,
        path: "/login-page",
        layout: "/auth",
        name: "Login Page",
        mini: "LP",
        component: LoginPage,
      },
    ],
  },
];
export default routes;
