import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
// core components
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import moments from "moment-timezone";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import {
  getOrder,
  cetakSo,
  cancelSo,
  cetakInv,
  editEmail,
  kirimEmailSo,
  kirimEmailInv,
  exportSo,
  updateStatusStor,
} from "stores";
import "./Pagination.css";
import CurrencyInput from "react-currency-input-field";

function SalesOrder() {
  const dispatch = useDispatch();
  const order = useSelector((state) => state.orderReducer);
  const auth = useSelector((state) => state.authReducer);
  const [listOrder, setListOrder] = React.useState([]);
  const [tglAwal, setTglAwal] = React.useState(
    new Date(new Date().getFullYear(), 0, 1).setHours(0, 0, 0, 0)
  );
  const [tglAkhir, setTglAkhir] = React.useState(new Date());

  const [modal, setModal] = React.useState(false);
  const [modalImage, setModalImage] = React.useState(false);
  const [modalEditEmail, setModalEditEmail] = React.useState(false);
  const [detailOrder, setDetailOrder] = React.useState(null);
  const [pending, setPending] = React.useState(false);
  const [emailAddress, setEmailAddress] = React.useState("");

  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [urlImage, setUrlImage] = React.useState("");
  const [emailValidasi, setEmailValidasi] = React.useState(false);
  const [statusStor, setStatusStor] = React.useState("");
  const [statusStorValidasi, setStatusStorValidasi] = React.useState(false);
  const [modalStor, setModalStor] = React.useState(false);
  const exportAllSo = async () => {
    Swal.fire({
      title: "Loading...!",
      text: "Download data order sedang berlangsung,tunggu proses hingga selesai!",
      icon: "warning",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    exportSo(dispatch, {
      tglAwal: moments
        .tz(new Date(tglAwal), "Asia/Jakarta")
        .format("YYYY-MM-DD"),
      tglAkhir: moments
        .tz(new Date(tglAkhir), "Asia/Jakarta")
        .format("YYYY-MM-DD"),
    }).then((respon) => {
      if (respon.status === 200) {
        const url = window.URL.createObjectURL(new Blob([respon.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Export SO-${moments
            .tz(tglAwal, "Asia/Jakarta")
            .format("DD-MM-YYYY")} sampai ${moments
            .tz(tglAkhir, "Asia/Jakarta")
            .format("DD-MM-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        Swal.fire({
          title: "Download Berhasil",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: !respon.data
            ? "Gagal download data order"
            : respon.data.message,
        });
      }
    });
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const handleClearSearch = () => {
    setSearchText("");
  };
  const cetakInvPdf = (data) => {
    cetakInv({ idSo: data.idSo, idInv: data.idInvoice }).then((response) => {
      if (response.status === 200) {
        const byteCharacters = atob(response.data.base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const blob = new Blob([byteArray], { type: "application/pdf" });
        const blobUrl = URL.createObjectURL(blob);
        const newTab = window.open(blobUrl, "_blank");
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: !response.data
            ? "Gagal menambahkan Produk"
            : response.data.message,
        });
      }
    });
  };

  const editEmailAddress = async () => {
    let data = {
      email:
        emailAddress === "" ||
        emailAddress === undefined ||
        emailAddress === null
          ? ""
          : emailAddress,
      idSo: detailOrder.idSo,
    };

    Swal.fire({
      title: `Apakah anda yakin untuk mengupdate email?`,
      text: `${detailOrder.idSo}-${detailOrder.namaCustomer}-${detailOrder.customerInstansi}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Update!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading...!",
          text: "Update data sedang berlangsung,tunggu proses hingga selesai!",
          icon: "warning",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        editEmail(data).then((response) => {
          setModalEditEmail(false);
          setDetailOrder(null);
          getOrder(dispatch, {
            cari: searchText,
            page: page + 1,
            limit: totalPerHalaman,
            tglAwal: moments
              .tz(new Date(tglAwal), "Asia/Jakarta")
              .format("YYYY-MM-DD"),
            tglAkhir: moments
              .tz(new Date(tglAkhir), "Asia/Jakarta")
              .format("YYYY-MM-DD"),
          });
          Swal.fire({
            title: response.data.message,
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
        });
      }
    });
  };
  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];
  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };

  const customStyles = {
    rows: {
      style: {
        marginTop: 5,
        marginBottom: 5,
        // height: 100, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const cetakSoPdf = (idSo) => {
    cetakSo({ idSo: idSo }).then((response) => {
      if (response.status === 200) {
        const byteCharacters = atob(response.data.base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const blob = new Blob([byteArray], { type: "application/pdf" });
        const blobUrl = URL.createObjectURL(blob);
        const newTab = window.open(blobUrl, "_blank");
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: !response.data
            ? "Gagal menambahkan Produk"
            : response.data.message,
        });
      }
    });
  };

  const kirimEmailCustomer = (data) => {
    let newData = {
      idSo: data.idSo,
      email: data.email,
    };
    Swal.fire({
      title: `Apakah anda yakin untuk mengirim email ke Customer?`,
      text: `${data.idSo}-${data.namaCustomer}-${data.customerInstansi}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Kirim!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading...!",
          text: "Kirim email sedang berlangsung,tunggu proses hingga selesai!",
          icon: "warning",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        kirimEmailSo(newData).then((response) => {
          getOrder(dispatch, {
            cari: searchText,
            page: page + 1,
            limit: totalPerHalaman,
            tglAwal: moments
              .tz(new Date(tglAwal), "Asia/Jakarta")
              .format("YYYY-MM-DD"),
            tglAkhir: moments
              .tz(new Date(tglAkhir), "Asia/Jakarta")
              .format("YYYY-MM-DD"),
          });
          Swal.fire({
            title: response.data.message,
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
        });
      }
    });
  };

  const kirimEmailInvCustomer = (data) => {
    let newData = {
      idSo: data.idSo,
      email: detailOrder.email,
      idInvoice: data.idInvoice,
    };
    Swal.fire({
      title: `Apakah anda yakin untuk mengirim email ke Customer?`,
      text: `${data.idInvoice}-${detailOrder.namaCustomer}-${detailOrder.customerInstansi}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Kirim!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading...!",
          text: "Kirim email sedang berlangsung,tunggu proses hingga selesai!",
          icon: "warning",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        kirimEmailInv(newData).then((response) => {
          getOrder(dispatch, {
            cari: searchText,
            page: page + 1,
            limit: totalPerHalaman,
            tglAwal: moments
              .tz(new Date(tglAwal), "Asia/Jakarta")
              .format("YYYY-MM-DD"),
            tglAkhir: moments
              .tz(new Date(tglAkhir), "Asia/Jakarta")
              .format("YYYY-MM-DD"),
          });
          Swal.fire({
            title: response.data.message,
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
        });
      }
    });
  };

  const updateStor = () => {
    if (statusStor === "") {
      setStatusStorValidasi(true);
    } else {
      Swal.fire({
        title: `Apakah anda yakin untuk mengupdate status stor?`,
        text: `${detailOrder.idSo}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Update!",
      }).then((result) => {
        if (result.isConfirmed) {
          let dataKirim = {
            idSo: detailOrder.idSo,
            statusStor: statusStor.value,
            dateSetor: moments
              .tz(new Date(), "Asia/Jakarta")
              .format("YYYY-MM-DD"),
          };

          Swal.fire({
            title: "Loading...!",
            text: "Update status stor sedang berlangsung,tunggu proses hingga selesai!",
            icon: "warning",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });

          updateStatusStor(dataKirim).then((response) => {
            if (response.data.status === 200) {
              setModalStor(false);
              setPending(false);
              setStatusStor("");
              setStatusStorValidasi(false);
              setDetailOrder(null);
              getOrder(dispatch, {
                cari: searchText,
                page: page + 1,
                limit: totalPerHalaman,
                tglAwal: moments
                  .tz(new Date(tglAwal), "Asia/Jakarta")
                  .format("YYYY-MM-DD"),
                tglAkhir: moments
                  .tz(new Date(tglAkhir), "Asia/Jakarta")
                  .format("YYYY-MM-DD"),
              });
              Swal.fire({
                title: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              setPending(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: !response.data
                  ? "Gagal melakukan pengajuan"
                  : response.data.message,
              });
            }
          });
        }
      });
    }
  };
  const columns = [
    {
      name: "No",
      width: "80px",
      center: true,
      selector: (row, index) => index + order.listOrderProduk.pagingCounter,
    },
    {
      name: "Nama Customer",
      width: "200px",
      selector: (row) => row.namaCustomer,
    },
    {
      name: "Order",
      width: "auto",
      selector: (row) => {
        return (
          <>
            <table cellPadding="4" border="1">
              <thead>
                <tr>
                  <th>Kode</th>
                  <th>Nama</th>
                  <th>Kategori</th>
                  <th>Sumber</th>
                  <th>Qty</th>
                  <th>Selesai</th>
                  <th>Harga/produk</th>
                  <th>Total Harga</th>
                  <th>Tambahan Online</th>
                  <th>Total Tambahan Online</th>
                </tr>
              </thead>
              <tbody>
                {row.orders.map((val) => {
                  return (
                    <>
                      <tr
                        style={{
                          backgroundColor:
                            val.qty != val.qtySelesai
                              ? "rgba(255, 3, 3,0.3)"
                              : null,
                        }}
                      >
                        <td>{val.produk.kodeProduk}</td>
                        <td>{val.produk.namaProduk}</td>
                        <td>
                          {val.produk.kategoriProduk === "upgrade"
                            ? "UPGRADE"
                            : "UTAMA"}
                        </td>
                        <td>{val.sumberProduk === "PL" ? "PL" : "SG"}</td>
                        <td>{val.qty} item</td>
                        <td style={{ textAlign: "center" }}>
                          {val.qtySelesai} item
                        </td>
                        <td>
                          {new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                            minimumFractionDigits: 0,
                          }).format(val.hargaProduk)}
                        </td>
                        <td>
                          {new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                            minimumFractionDigits: 0,
                          }).format(val.totalHarga)}
                        </td>
                        <td>
                          {new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                            minimumFractionDigits: 0,
                          }).format(
                            !val.tambahanHargaOnline
                              ? 0
                              : val.tambahanHargaOnline
                          )}
                        </td>
                        <td>
                          {new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                            minimumFractionDigits: 0,
                          }).format(
                            !val.totalTambahanHargaOnline
                              ? 0
                              : val.totalTambahanHargaOnline
                          )}
                        </td>
                      </tr>
                    </>
                  );

                  // <li style={{ marginTop: 5 }}>
                  //   <b>{val.produk.kodeProduk}</b> - {val.produk.namaProduk}
                  //   {val.produk.kategoriProduk === "upgrade" ? " - UPGRADE" : null}
                  //   {val.sumberProduk === "PL" ? (
                  //     <b>
                  //       {" "}
                  //       <i style={{ color: "blue" }}> - PL</i>
                  //     </b>
                  //   ) : null}{" "}
                  //   - <b>{val.qty} item</b>
                  // </li>
                })}
              </tbody>
            </table>
          </>
        );
      },
    },
    {
      name: "Total Harga",
      width: "200px",
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.totalPembelian);
        return formattedValue;
      },
    },
    {
      name: "Total Tambahan Harga Online",
      width: "200px",
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(!row.totalTambahanOnline ? 0 : row.totalTambahanOnline);
        return formattedValue;
      },
    },
    {
      name: "Status Order",
      width: "200px",
      center: true,
      selector: (row) =>
        row.statusSo === "Ajukan" ? (
          <div
            style={{
              backgroundColor: "rgba(255,163,47,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(255,163,47)",
            }}
          >
            <b style={{ color: "rgb(255,163,47)" }}>Diorder</b>
          </div>
        ) : row.statusSo === "Proses" ? (
          <div
            style={{
              backgroundColor: "rgba(36,204,239,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(36,204,239)",
            }}
          >
            <b style={{ color: "rgb(36,204,239)" }}>Diproses</b>
          </div>
        ) : row.statusSo === "Tolak" ? (
          <div
            style={{
              backgroundColor: "rgba(251,59,70,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(251,59,70)",
            }}
          >
            <b style={{ color: "rgb(251,59,70)" }}>Ditolak</b>
          </div>
        ) : row.statusSo === "Kirim" ? (
          <div
            style={{
              backgroundColor: "rgba(68,125,247,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(68,125,247)",
            }}
          >
            <b style={{ color: "rgb(68,125,247)" }}>Dikirim</b>
          </div>
        ) : row.statusSo === "Belum" ? (
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(0,0,0)",
            }}
          >
            <b style={{ color: "rgb(0,0,0)" }}>Belum Kirim</b>
          </div>
        ) : row.statusSo === "Pending" ? (
          <div
            style={{
              backgroundColor: "rgba(247, 0, 239,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(247, 0, 239)",
            }}
          >
            <b style={{ color: "rgb(247, 0, 239)" }}>Pending</b>
          </div>
        ) : row.statusSo === "Diambil" ? (
          <div
            style={{
              backgroundColor: "rgba(36,204,23,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(36,204,23)",
            }}
          >
            <b style={{ color: "rgb(36,204,23)" }}>Diambil</b>
          </div>
        ) : row.statusSo === "Cancel" ? (
          <div
            style={{
              backgroundColor: "rgba(251,59,70,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(251,59,70)",
            }}
          >
            <b style={{ color: "rgb(251,59,70)" }}>Cancel</b>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "rgba(36,204,23,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(36,204,23)",
            }}
          >
            <b style={{ color: "rgb(36,204,23)" }}>Selesai</b>
          </div>
        ),
    },
    {
      name: "Nama Instansi",
      width: "250px",
      selector: (row) => row.customerInstansi,
    },

    {
      name: "Status Pembayaran",
      width: "200px",
      center: true,
      selector: (row) =>
        row.statusPembayaran === "" || !row.statusPembayaran ? (
          <span style={{ color: "rgb(251,59,70)" }}>
            <b>-</b>
          </span>
        ) : row.statusPembayaran === "Pending" ? (
          <div
            style={{
              backgroundColor: "rgba(255,163,47,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(255,163,47)",
            }}
          >
            <b style={{ color: "rgb(255,163,47)" }}>Pending</b>
          </div>
        ) : row.statusPembayaran === "Proses" ? (
          <div
            style={{
              backgroundColor: "rgba(68,125,247,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(68,125,247)",
            }}
          >
            <b style={{ color: "rgb(68,125,247)" }}>Proses</b>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "rgba(36,204,23,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(36,204,23)",
            }}
          >
            <b style={{ color: "rgb(36,204,23)" }}>Lunas</b>
          </div>
        ),
    },
    // {
    //   name: "Status Lunas",
    //   width: "200px",
    //   center: true,
    //   selector: (row) =>
    //     row.statusLunas === false ? (
    //       <span style={{ color: "rgb(251,59,70)" }}>
    //         <b>Belum Lunas</b>
    //       </span>
    //     ) : (
    //       <span style={{ color: "rgb(36,204,23)" }}>
    //         <b>Lunas</b>
    //       </span>
    //     ),
    // },
    {
      name: "Tipe Order",
      width: "200px",
      center: true,
      selector: (row) => row.tipeSo,
    },
    {
      name: "Kode Order",
      width: "200px",
      center: true,
      selector: (row) => row.idSo,
    },

    {
      name: "Tanggal Order",
      width: "200px",
      center: true,
      selector: (row) =>
        moments.tz(new Date(row.tglOrder), "Asia/Jakarta").format("DD-MM-YYYY"),
    },
    {
      name: "Selesai Garansi",
      width: "200px",
      center: true,
      selector: (row) =>
        !row.selesaiGaransi
          ? "-"
          : moments
              .tz(new Date(row.selesaiGaransi), "Asia/Jakarta")
              .format("DD-MM-YYYY"),
    },
    {
      name: "Email",
      width: "200px",
      center: true,
      selector: (row) =>
        row.email === "" ? (
          <div
            onClick={() => {
              setDetailOrder(row);
              setModalEditEmail(true);
            }}
            style={{ cursor: "pointer", color: "blue" }}
          >
            <b>Tambah Email</b>
          </div>
        ) : (
          <div
            onClick={() => {
              setDetailOrder(row);
              setModalEditEmail(true);
            }}
            style={{ cursor: "pointer", color: "green" }}
          >
            <b> {row.email}</b>
          </div>
        ),
    },
    {
      name: "Status Setor",
      width: "200px",
      center: true,
      selector: (row) =>
        row.statusSetor === true ? (
          <div style={{ color: "green" }}>
            <b>Sudah Setor</b>
          </div>
        ) : (
          <div style={{ color: "red" }}>
            <b>Belum Setor</b>
          </div>
        ),
    },
    {
      name: "Aksi",
      width: "200px",
      center: true,
      selector: (row) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <div
                onClick={() => {
                  setDetailOrder(row);
                  setModal(true);
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Detail</Tooltip>}
                >
                  <i
                    style={{ color: "#447DF7", fontSize: 16 }}
                    className="fas fa-info-circle"
                  ></i>
                </OverlayTrigger>
              </div>
              <div
                onClick={() => {
                  cetakSoPdf(row.idSo);
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Cetak PDF</Tooltip>}
                >
                  <i
                    style={{ color: "#FF0000", fontSize: 16 }}
                    className="fas fa-file-pdf"
                  ></i>
                </OverlayTrigger>
              </div>
              <div
                onClick={() => {
                  // cetakSoPdf(row.idSo);
                  if (row.email === "") {
                    Swal.fire({
                      title: "Warning",
                      text: "Tambahkan Email untuk dapat mengirim Email ke Customer",
                      icon: "warning",
                      showConfirmButton: true,
                      // timer: 1500,
                    });
                  } else {
                    kirimEmailCustomer(row);
                  }
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: row.email === "" ? "unset" : "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-48903503">Kirim Email SO</Tooltip>
                  }
                >
                  <i
                    style={{
                      color: row.email === "" ? "grey" : "#fcba03",
                      fontSize: 16,
                    }}
                    className="fas fa-envelope-open-text"
                  ></i>
                </OverlayTrigger>
              </div>
              {(auth.responLogin && auth.responLogin.role === "Super Admin") ||
              (auth.responLogin && auth.responLogin.role === "Management") ? (
                <div
                  onClick={() => {
                    if (
                      row.statusSo === "Kirim" ||
                      row.statusSo === "Diambil" ||
                      row.statusSo === "Cancel"
                    ) {
                      null;
                    } else {
                      Swal.fire({
                        title: `Apakah anda yakin untuk meng-cancel order ${row.idSo} ini?`,
                        text: "Pastikan order yang anda pilih sudah benar!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Ok!",
                        cancelButtonText: "Batal!",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          Swal.fire({
                            title: "Loading...!",
                            text: "Proses Cancel sedang dilakukan,tunggu proses hingga selesai!",
                            icon: "warning",
                            allowOutsideClick: false,
                            didOpen: () => {
                              Swal.showLoading();
                            },
                          });

                          cancelSo({ idSo: row.idSo }).then((response) => {
                            if (response.status === 200) {
                              getOrder(dispatch, {
                                cari: searchText,
                                page: page + 1,
                                limit: totalPerHalaman,
                                tglAwal: moments
                                  .tz(new Date(tglAwal), "Asia/Jakarta")
                                  .format("YYYY-MM-DD"),
                                tglAkhir: moments
                                  .tz(new Date(tglAkhir), "Asia/Jakarta")
                                  .format("YYYY-MM-DD"),
                              });
                              Swal.fire({
                                title: response.data.message,
                                icon: "success",
                                showConfirmButton: false,
                                timer: 1500,
                              });
                            } else {
                              getOrder(dispatch, {
                                cari: searchText,
                                page: page + 1,
                                limit: totalPerHalaman,
                                tglAwal: moments
                                  .tz(new Date(tglAwal), "Asia/Jakarta")
                                  .format("YYYY-MM-DD"),
                                tglAkhir: moments
                                  .tz(new Date(tglAkhir), "Asia/Jakarta")
                                  .format("YYYY-MM-DD"),
                              });
                              Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: !response.data
                                  ? "Gagal mengcancel order"
                                  : response.data.message,
                              });
                            }
                          });
                        }
                      });
                    }
                  }}
                  style={{
                    width: 25,
                    height: 25,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor:
                      row.statusSo === "Kirim" ||
                      row.statusSo === "Diambil" ||
                      row.statusSo === "Cancel"
                        ? "unset"
                        : "pointer",
                    margin: 10,
                  }}
                >
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-48903503">Cancel SO</Tooltip>}
                  >
                    <i
                      style={{
                        color:
                          row.statusSo === "Kirim" ||
                          row.statusSo === "Diambil" ||
                          row.statusSo === "Cancel"
                            ? "grey"
                            : "#FF0000",
                        fontSize: 16,
                      }}
                      className="fas fa-times-circle"
                    ></i>
                  </OverlayTrigger>
                </div>
              ) : null}
              {(auth.responLogin && auth.responLogin.role === "Super Admin") ||
              (auth.responLogin && auth.responLogin.role === "Management") ? (
                <>
                  <div
                    onClick={() => {
                      setModalStor(true);
                      setDetailOrder(row);
                    }}
                    style={{
                      width: 25,
                      height: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      margin: 10,
                    }}
                  >
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-48903503">Update Stor </Tooltip>
                      }
                    >
                      <i
                        style={{ color: "#478964", fontSize: 16 }}
                        className="fas fa-edit"
                      ></i>
                    </OverlayTrigger>
                  </div>
                  {/* <div
                    style={{
                      width: 25,
                      height: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      margin: 10,
                    }}
                  >
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-48903503">Update Stor</Tooltip>
                      }
                    >
                      <i
                        style={{ color: "#FF0000", fontSize: 16 }}
                        className="fas fa-trash"
                      ></i>
                    </OverlayTrigger>
                  </div> */}
                </>
              ) : null}
            </div>
          </>
        );
      },
    },
    // {
    //   name: "Stock",
    //   width: "200px",
    //   center: true,
    //   selector: (row) => row.jumlahStock,
    // },
    // {
    //   name: "Minimal Stock",
    //   width: "200px",
    //   center: true,
    //   selector: (row) => row.minimalStock,
    // },
    // {
    //   name: "Tanggal Data Dibuat",
    //   width: "200px",
    //   center: true,
    //   selector: (row) =>
    //     moments
    //       .tz(new Date(row.createdAt), "Asia/Jakarta")
    //       .format("DD-MM-YYYY HH:mm:ss"),
    // },
    // {
    //   name: "Tanggal Update Data",
    //   width: "200px",
    //   center: true,
    //   selector: (row) =>
    //     moments
    //       .tz(new Date(row.updatedAt), "Asia/Jakarta")
    //       .format("DD-MM-YYYY HH:mm:ss"),
    // },
  ];

  const history = useHistory();
  React.useEffect(() => {
    let tmp =
      order.listOrderProduk.data &&
      order.listOrderProduk.data.map((val, index) => {
        return {
          ...val,
        };
      });
    setListOrder(tmp);
    if (order.listOrderProduk.length != 0) {
      setPending(false);
    }
  }, [order.listOrderProduk]);

  React.useEffect(() => {
    if (searchText != "") {
      setPage(0);
    }
    getOrder(dispatch, {
      cari: searchText,
      page: page + 1,
      limit: totalPerHalaman,
      tglAwal: moments
        .tz(new Date(tglAwal), "Asia/Jakarta")
        .format("YYYY-MM-DD"),
      tglAkhir: moments
        .tz(new Date(tglAkhir), "Asia/Jakarta")
        .format("YYYY-MM-DD"),
    });
  }, [page, totalPerHalaman, searchText, tglAkhir, tglAwal]);

  return (
    <>
      <Container fluid>
        <Row
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <Col>
            <div style={{ fontSize: 25, fontWeight: "bold" }}>Sales Order</div>
          </Col>
          <Col style={{ textAlign: "right" }}>
            {/* <Button
              style={{ marginLeft: 20, marginBottom: 0 }}
              onClick={() => {
                setModal(true);
              }}
            >
              Tambah Produk
            </Button> */}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div>
                <InputGroup>
                  <Form.Control
                    style={{
                      paddingLeft: "10px",
                      width: "300px",
                    }}
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Kode Order/Nama Instansi/Nama Customer"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      style={{ cursor: "pointer" }}
                      onClick={handleClearSearch}
                    >
                      <i class="fas fa-times"></i>
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </div>
          </Col>
        </Row>
        <Col md="12">
          <Row>
            <Col md="2">
              <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                Tanggal Awal
              </div>
              <Form.Group
                style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
              >
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Date Picker Here",
                    readOnly: true,
                    style: {
                      cursor: "default",
                      color: "black",
                      backgroundColor: "white",
                    },
                  }}
                  onChange={(e) => {
                    e.toDate().setHours(0, 0, 0, 0),
                      setTglAwal(e.toDate().setHours(0, 0, 0, 0));
                  }}
                  value={tglAwal}
                  initialValue={tglAwal}
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                ></ReactDatetime>
              </Form.Group>
            </Col>
            <Col md="2">
              <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                Tanggal Akhir
              </div>
              <Form.Group
                style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
              >
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Date Picker Here",
                    readOnly: true,
                    style: {
                      cursor: "default",
                      color: "black",
                      backgroundColor: "white",
                    },
                  }}
                  onChange={(e) => {
                    e.toDate().setHours(23, 59, 0, 0),
                      setTglAkhir(e.toDate().setHours(23, 59, 0, 0));
                  }}
                  value={tglAkhir}
                  initialValue={tglAkhir}
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                ></ReactDatetime>
              </Form.Group>
            </Col>
            <Col md="8" style={{ textAlign: "right" }}>
              <Button
                style={{
                  marginLeft: 20,
                  marginBottom: 0,
                  backgroundColor: "rgba(40,118,74,1)",
                  borderColor: "rgba(40,118,74,1)",
                }}
                onClick={() => {
                  exportAllSo();
                }}
              >
                <i
                  className="fas fa-file-excel"
                  style={{ marginRight: 10 }}
                ></i>
                Export Order
              </Button>
            </Col>
          </Row>
        </Col>

        {/* <Row>
          <Col>
            <Button
              onClick={() => {
                setModalImport(true);
              }}
              className="btn-social"
              variant="twitter"
              style={{
                backgroundColor: "rgba(40,118,74,1)",
                borderColor: "rgba(40,118,74,1)",
              }}
            >
              <i className="fas fa-file-excel" style={{ marginRight: 10 }}></i>
              Import Excel
            </Button>
            <Button
              onClick={() => {
                window.open(
                  "https://docs.google.com/spreadsheets/d/1Qxfk5Xe0Xbjr39htdHLRoiA6_wSCEWaN/edit?usp=sharing&ouid=116475921970373937456&rtpof=true&sd=true",
                  "_blank"
                );
              }}
              className="btn-social"
              variant="twitter"
              style={{
                backgroundColor: "rgba(64,123,255,1)",
                borderColor: "rgba(64,123,255,1)",
                marginLeft: 10,
              }}
            >
              <i
                className="fas fa-cloud-download-alt"
                style={{ marginRight: 10 }}
              ></i>
              Download Format Import
            </Button>
          </Col>
          <Col>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div>
                <InputGroup>
                  <Form.Control
                    style={{
                      paddingLeft: "10px",
                      width: "300px",
                    }}
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Nama Mesin/Kode Mesin"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      style={{ cursor: "pointer" }}
                      onClick={handleClearSearch}
                    >
                      <i class="fas fa-times"></i>
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </div>
          </Col>
        </Row> */}

        <Row>
          <Col md="12">
            <Card>
              <Card.Body>
                <DataTable
                  columns={columns}
                  data={listOrder}
                  //   pagination
                  customStyles={customStyles}
                  paginationPerPage={totalPerHalaman}
                  //   onChangePage={handlePageChange}
                  progressPending={pending}
                />

                <div style={{ marginTop: 35 }}>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col>
                      <Row style={{ alignItems: "center" }}>
                        <div
                          style={{
                            paddingRight: 0,
                            marginLeft: 20,
                            marginRight: 10,
                          }}
                        >
                          <label
                            style={{
                              fontWeight: "bold",
                              textTransform: "none",
                              fontSize: 14,
                            }}
                          >
                            Rows per page
                          </label>
                        </div>
                        <Col sm="3" style={{ paddingLeft: 0 }}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="Pilih Client"
                            value={options.find(
                              (option) => option.value === totalPerHalaman
                            )}
                            onChange={handlePageSizeChange}
                            options={options}
                            placeholder="Rows per page"
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col>
                      <ReactPaginate
                        forcePage={page}
                        containerClassName="paginations justify-content-end"
                        previousClassName="pages-items-containers"
                        previousLinkClassName="pages-links-labels"
                        nextClassName="pages-items-containers"
                        nextLinkClassName="pages-links-labels"
                        breakClassName="pages-items-containers"
                        pageCount={order.listOrderProduk.totalPages}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        pageLinkClassName="pages-links-labels"
                        pageClassName="pages-items-containers"
                        activeClassName="actives"
                        activeLinkClassName="activess"
                        nextLabel=" > "
                        previousLabel=" < "
                        initialPage={page}
                        disableInitialCallback={true}
                        onPageChange={(val) => {
                          setPage(val.selected);
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Modal Import Produk */}
      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Detail Order
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Kode Order
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailOrder && detailOrder.idSo}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Instansi
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailOrder && detailOrder.customerInstansi}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Nama Customer
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailOrder && detailOrder.namaCustomer}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Alamat
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailOrder && detailOrder.alamatCustomer}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  No Telpn
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailOrder && detailOrder.noTelp}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Email
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailOrder && detailOrder.email === ""
                    ? "-"
                    : detailOrder && detailOrder.email}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Tanggal Order
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailOrder &&
                    moments
                      .tz(new Date(detailOrder.tglOrder), "Asia/Jakarta")
                      .format("DD-MM-YYYY")}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Selesai Garansi
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailOrder && !detailOrder.selesaiGaransi
                    ? "-"
                    : detailOrder &&
                      moments
                        .tz(
                          new Date(detailOrder.selesaiGaransi),
                          "Asia/Jakarta"
                        )
                        .format("DD-MM-YYYY")}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Nama Sales
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailOrder && detailOrder.sales.namaLengkap}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Status Order
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailOrder && detailOrder.statusSo === "Ajukan" ? (
                    <b style={{ color: "rgb(255,163,47)" }}>Diorder</b>
                  ) : detailOrder && detailOrder.statusSo === "Proses" ? (
                    <b style={{ color: "rgb(36,204,239)" }}>Diproses</b>
                  ) : detailOrder && detailOrder.statusSo === "Tolak" ? (
                    <b style={{ color: "rgb(251,59,70)" }}>Ditolak</b>
                  ) : detailOrder && detailOrder.statusSo === "Kirim" ? (
                    <b style={{ color: "rgb(68,125,247)" }}>Dikirim</b>
                  ) : detailOrder && detailOrder.statusSo === "Belum" ? (
                    <b style={{ color: "rgb(0,0,0)" }}>Belum Dikirim</b>
                  ) : detailOrder && detailOrder.statusSo === "Pending" ? (
                    <b style={{ color: "rgb(247, 0, 239)" }}>Pending</b>
                  ) : detailOrder && detailOrder.statusSo === "Diambil" ? (
                    <b style={{ color: "rgb(36,204,23)" }}>Diambil</b>
                  ) : detailOrder && detailOrder.statusSo === "Cancel" ? (
                    <b style={{ color: "rgb(251,59,70)" }}>Cancel</b>
                  ) : (
                    <b style={{ color: "rgb(36,204,23)" }}>Selesai</b>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Tipe Order
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailOrder && detailOrder.tipeSo}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Status Pembayaran
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {(detailOrder && !detailOrder.statusPembayarna) ||
                  (detailOrder && detailOrder.statusPembayarna === "") ? (
                    <span>
                      <b>-</b>
                    </span>
                  ) : detailOrder &&
                    detailOrder.statusPembayarna === "Pending" ? (
                    <span style={{ color: "rgb(255,163,47)" }}>
                      <b>Pending</b>
                    </span>
                  ) : detailOrder &&
                    detailOrder.statusPembayarna === "Proses" ? (
                    <span style={{ color: "rgb(68,125,247)" }}>
                      <b>Proses</b>
                    </span>
                  ) : (
                    <span style={{ color: "rgb(36,204,23)" }}>
                      <b>Lunas</b>
                    </span>
                  )}
                </Col>
              </Row>
              {/* <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Status Lunas
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailOrder && detailOrder.statusLunas === false ? (
                    <span style={{ color: "rgb(251,59,70)" }}>
                      <b>Belum Lunas</b>
                    </span>
                  ) : (
                    <span style={{ color: "rgb(36,204,23)" }}>
                      <b>Lunas</b>
                    </span>
                  )}
                </Col>
              </Row> */}
              <div style={{ marginTop: 20 }}>
                <b>Bukti Dokumen Order</b>
              </div>
              <hr></hr>
              <Row>
                {detailOrder &&
                  detailOrder.buktiFoto.map((val, index) => {
                    return (
                      <>
                        <Col sm="4">
                          <Image
                            onClick={() => {
                              setUrlImage(val);
                              setModalImage(true);
                            }}
                            src={val}
                            style={{
                              objectFit: "contain",
                              width: "200px",
                              height: "300px",
                              cursor: "pointer",
                            }}
                            thumbnail
                          />
                        </Col>
                      </>
                    );
                  })}
              </Row>
              <div style={{ marginTop: 20 }}>
                <b>Data Pembelian</b>
              </div>
              <hr></hr>
              <div style={{ overflowX: "auto" }}>
                <table border="1" cellPadding="8" cellSpacing="0">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Kode Produk</th>
                      <th>Nama Produk</th>
                      <th>Tipe Produk</th>
                      <th>Kategori Produk</th>
                      <th>Kapasitas Produk</th>
                      <th>Sumber Produk</th>
                      <th>Tanggal Awal Pengerjaan</th>
                      <th>Tanggal Deadline</th>
                      <th>Tanggal Selesai</th>
                      <th>Status Produk</th>
                      <th>Harga Satuan</th>
                      <th>Nominal Tambahan Online</th>
                      <th>Qty</th>
                      <th>Selesai</th>
                      <th>Total Harga</th>
                      <th>Total Tambahan Harga Online</th>
                    </tr>
                  </thead>
                  <tbody>
                    {detailOrder &&
                      detailOrder.orders.map((item, index) => {
                        return (
                          <tr
                            key={item.produk.kodeProduk}
                            style={{
                              backgroundColor:
                                item.qty != item.qtySelesai
                                  ? "rgba(255, 3, 3,0.3)"
                                  : null,
                            }}
                          >
                            <td style={{ textAlign: "center" }}>{index + 1}</td>
                            <td>{item.produk.kodeProduk}</td>
                            <td>{item.produk.namaProduk}</td>
                            <td>{item.produk.tipe}</td>
                            <td>
                              {item.produk.kategoriProduk === "upgrade"
                                ? "UPGRADE"
                                : "UTAMA"}
                            </td>
                            <td>{item.produk.kapasitas}</td>
                            <td>
                              {item.sumberProduk === "PL"
                                ? "Pesan Langsung"
                                : "Gudang"}
                            </td>
                            <td>
                              {!item.tglAwalPengerjaan ||
                              item.tglAwalPengerjaan === ""
                                ? "-"
                                : moments
                                    .tz(
                                      new Date(item.tglAwalPengerjaan),
                                      "Asia/Jakarta"
                                    )
                                    .format("DD-MM-YYYY")}
                            </td>
                            <td>
                              {!item.tglDeadline || item.tglDeadline === ""
                                ? "-"
                                : moments
                                    .tz(
                                      new Date(item.tglDeadline),
                                      "Asia/Jakarta"
                                    )
                                    .format("DD-MM-YYYY")}
                            </td>
                            <td>
                              {!item.tglSelesai || item.tglSelesai === ""
                                ? "-"
                                : moments
                                    .tz(
                                      new Date(item.tglSelesai),
                                      "Asia/Jakarta"
                                    )
                                    .format("DD-MM-YYYY")}
                            </td>
                            <td>
                              {item.statusProduk === "" || !item.statusProduk
                                ? "-"
                                : item.statusProduk}
                            </td>
                            <td>
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(item.produk.hargaProduk)}
                            </td>
                            <td>
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(
                                !item.tambahanHargaOnline
                                  ? 0
                                  : item.tambahanHargaOnline
                              )}
                            </td>
                            <td style={{ textAlign: "center" }}>{item.qty}</td>
                            <td style={{ textAlign: "center" }}>
                              {item.qtySelesai}
                            </td>
                            <td>
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(item.totalHarga)}
                            </td>
                            <td>
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(
                                !item.totalTambahanHargaOnline
                                  ? 0
                                  : item.totalTambahanHargaOnline
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="15">
                        <b>Total Pembelian</b>
                      </td>
                      <td>
                        <b>
                          {new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                            minimumFractionDigits: 0,
                          }).format(detailOrder && detailOrder.totalPembelian)}
                        </b>
                      </td>
                      <td>
                        <b>
                          {new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                            minimumFractionDigits: 0,
                          }).format(
                            detailOrder && !detailOrder.totalTambahanOnline
                              ? 0
                              : detailOrder && detailOrder.totalTambahanOnline
                          )}
                        </b>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div style={{ marginTop: 20 }}>
                <b>Data Invoice</b>
              </div>
              <hr></hr>
              <div style={{ overflowX: "auto" }}>
                <table border="1" cellPadding="8" cellSpacing="0">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Kirim Email</th>
                      <th>No Invoice</th>
                      <th>Tgl Invoice</th>
                      <th>Tipe Pembayaran</th>
                      <th>Nama Admin</th>
                      <th>Total Pembelian</th>
                      <th>Nominal Admin</th>
                      <th>Nominal Peti</th>
                      <th>Nominal Ongkir</th>
                      <th>Nominal Diskon</th>
                      <th>Nominal Bayar</th>
                      <th>Nominal DP</th>
                      <th>Nominal Lunas</th>
                      <th>Nominal Sisa Bayar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {detailOrder && detailOrder.invoices.length === 0 ? (
                      <tr>
                        <td colSpan="14">
                          <span style={{ color: "grey" }}>
                            Belum memiliki data invoice
                          </span>
                        </td>
                      </tr>
                    ) : (
                      detailOrder &&
                      detailOrder.invoices.map((item, index) => {
                        return (
                          <tr key={item.idInvoice}>
                            <td style={{ textAlign: "center" }}>{index + 1}</td>
                            <td>
                              <div
                                onClick={() => {
                                  // cetakSoPdf(row.idSo);

                                  if (detailOrder.email === "") {
                                    Swal.fire({
                                      title: "Warning",
                                      text: "Tambahkan Email untuk dapat mengirim Email ke Customer",
                                      icon: "warning",
                                      showConfirmButton: true,
                                      // timer: 1500,
                                    });
                                  } else {
                                    kirimEmailInvCustomer(item);
                                  }
                                }}
                                style={{
                                  width: 25,
                                  height: 25,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  cursor:
                                    detailOrder.email === ""
                                      ? "unset"
                                      : "pointer",
                                  margin: 10,
                                }}
                              >
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-48903503">
                                      Kirim Email SO
                                    </Tooltip>
                                  }
                                >
                                  <i
                                    style={{
                                      color:
                                        detailOrder.email === ""
                                          ? "grey"
                                          : "#fcba03",
                                      fontSize: 16,
                                    }}
                                    className="fas fa-envelope-open-text"
                                  ></i>
                                </OverlayTrigger>
                              </div>
                            </td>
                            <td>
                              {index === 0 ? (
                                <div
                                  onClick={() => {
                                    cetakInvPdf(item);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    color: "blue",
                                  }}
                                >
                                  <b>{item.idInvoice}</b>
                                </div>
                              ) : (
                                item.idInvoice
                              )}
                            </td>
                            <td>
                              {moments
                                .tz(new Date(item.tglInvoice), "Asia/Jakarta")
                                .format("DD-MM-YYYY")}
                            </td>
                            <td>{item.tipePembayaran}</td>
                            <td>{item.user.namaLengkap}</td>
                            <td>
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(item.totalPembelian)}
                            </td>
                            <td>
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(item.nominalAdmin)}
                            </td>
                            <td>
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(item.nominalPeti)}
                            </td>
                            <td>
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(item.nominalOngkir)}
                            </td>
                            <td>
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(item.nominalDiskon)}
                            </td>
                            <td>
                              {index === 0
                                ? new Intl.NumberFormat("id-ID", {
                                    style: "currency",
                                    currency: "IDR",
                                    minimumFractionDigits: 0,
                                  }).format(
                                    item.totalPembelian +
                                      item.nominalAdmin +
                                      item.nominalPeti +
                                      item.nominalOngkir -
                                      item.nominalDiskon
                                  )
                                : item.tipePembayaran === "DP"
                                ? new Intl.NumberFormat("id-ID", {
                                    style: "currency",
                                    currency: "IDR",
                                    minimumFractionDigits: 0,
                                  }).format(item.nominalDp)
                                : new Intl.NumberFormat("id-ID", {
                                    style: "currency",
                                    currency: "IDR",
                                    minimumFractionDigits: 0,
                                  }).format(item.nominalLunas)}
                            </td>
                            <td>
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(item.nominalDp)}
                            </td>
                            <td>
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(item.nominalLunas)}
                            </td>
                            <td>
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(item.nominalSisaBayar)}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>

              <div style={{ marginTop: 20 }}>
                <b>Data Surat Jalan</b>
              </div>
              <hr></hr>
              <div style={{ overflowX: "auto" }}>
                <table border="1" cellPadding="8" cellSpacing="0">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Kode Surat Jalan</th>
                      <th>Nomor Resi</th>
                      <th>Nama Admin</th>
                      <th>Tanggal Surat</th>
                      <th>Keterangan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {detailOrder && detailOrder.suratJalan.length === 0 ? (
                      <tr>
                        <td colSpan="14">
                          <span style={{ color: "grey" }}>
                            Belum memiliki data surat jalan
                          </span>
                        </td>
                      </tr>
                    ) : (
                      detailOrder &&
                      detailOrder.suratJalan.map((item, index) => {
                        return (
                          <tr key={item.idSuratJalan}>
                            <td style={{ textAlign: "center" }}>{index + 1}</td>
                            <td>{item.idSuratJalan}</td>
                            <td>{item.nomorResi}</td>
                            <td>{item.user.namaLengkap}</td>
                            <td>
                              {moments
                                .tz(new Date(item.tglSurat), "Asia/Jakarta")
                                .format("DD-MM-YYYY")}
                            </td>
                            <td>{item.keterangan}</td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
              <div style={{ marginTop: 20 }}>
                <b>Bukti Dokumen Surat Jalan</b>
              </div>
              <hr></hr>
              <Row>
                {detailOrder && detailOrder.suratJalan.length === 0 ? (
                  <span style={{ color: "grey", marginLeft: 20 }}>
                    Belum memiliki dokumen surat jalan.
                  </span>
                ) : (
                  detailOrder &&
                  detailOrder.suratJalan[0].buktiFoto.map((val, index) => {
                    return (
                      <>
                        <Col sm="4">
                          <Image
                            onClick={() => {
                              setUrlImage(val);
                              setModalImage(true);
                            }}
                            src={val}
                            style={{
                              objectFit: "contain",
                              width: "200px",
                              height: "300px",
                              cursor: "pointer",
                            }}
                            thumbnail
                          />
                        </Col>
                      </>
                    );
                  })
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      {/* Modal show image */}
      <Modal
        size="lg"
        show={modalImage}
        onHide={() => {
          setModalImage(false);
          setUrlImage("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Detail Gambar
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Image
                src={urlImage}
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                }}
                thumbnail
              />
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      {/* Modal Edit Email */}
      <Modal
        size="md"
        show={modalEditEmail}
        onHide={() => {
          setModalEditEmail(false);
          setDetailOrder(null);
          setEmailAddress("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Edit Email Customer {detailOrder && detailOrder.namaCustomer}{" "}
                {detailOrder && detailOrder.customerInstansi}
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Email Customer
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setEmailAddress(e.target.value);
                    }}
                    placeholder="Email Customer"
                    type="text"
                  ></Form.Control>
                </Form.Group>
              </Form>
            </Card.Body>
            <Card.Footer style={{ textAlign: "end" }}>
              <Button
                className="btn-fill"
                type="submit"
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={editEmailAddress}
              >
                ubah
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      {/* Modal Edit Stor */}
      <Modal
        size="md"
        show={modalStor}
        onHide={() => {
          setModalStor(false);
          setStatusStor("");
          setStatusStorValidasi(false);
          setDetailOrder(null);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Update Status Stor
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Status Stor
                  </label>

                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={statusStor}
                    onChange={(value) => {
                      setStatusStor(value);
                      setStatusStorValidasi(false);
                    }}
                    options={[
                      { value: false, label: "Belum Stor" },
                      { value: true, label: "Sudah Stor" },
                    ]}
                    placeholder="Pilih Status Stor"
                  />
                  {statusStorValidasi ? (
                    <label style={{ textTransform: "none", color: "red" }}>
                      *status setor tidak boleh kosong
                    </label>
                  ) : null}
                </Form.Group>
              </Form>
            </Card.Body>
            <Card.Footer style={{ textAlign: "end" }}>
              <Button
                className="btn-fill"
                type="submit"
                // variant="info"
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={(e) => {
                  e.preventDefault();
                  updateStor();
                }}
              >
                Update
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>
    </>
  );
}

export default SalesOrder;
