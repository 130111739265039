import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import routes from "../../routes";

export async function getKategoriAsset(dispatch, data, history) {
  try {
    const response = await baseAxios("/kategoriAssetWeb/getKategoriAsset", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALLKATEGORIASSET", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function getKategoriAssetDropdown(dispatch, data, history) {
  try {
    const response = await baseAxios(
      "/kategoriAssetWeb/getDropDownKategoriAsset",
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALLKATEGORIASSETDROPDOWN", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function tambahKategoriAsset(data) {
  try {
    const response = await baseAxios.post(
      "/kategoriAssetWeb/addKategoriAsset",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function nonAktifKategoriAsset(data) {
  try {
    const response = await baseAxios.post(
      "/kategoriAssetWeb/nonAktifKategoriAsset",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function deleteKategoriAsset(data) {
  try {
    const response = await baseAxios.post(
      "/kategoriAssetWeb/deleteKategoriAsset",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}
